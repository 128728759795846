import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { lookupsJson, hideHighestEducationStates, hideGenderStates, noCreditStates } from '@ourbranch/lookups';

import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import CollapsibleCard from 'core/components/collapsible-card';
import PersonActions from './person-actions/person-actions';
import withDatePicker from 'core/components/with-date-picker';

import useStyles from './person.styles';

function Applicant({
  index,
  fieldId: id,
  fieldName,
  item,
  removeFromList,
  onRemove,
  initialFold,
  onFold,
  disabled,
  fromPolicy,
  allowLicensedActions,
  swapPrimaryApplicant,
  showApplicantSwapButton,
  disableSwapButton,
  disableNameChange
}) {
  const classes = useStyles();
  const { values } = useFormikContext();
  const state = values.home?.homeLocation?.state || values.rentersCoverage?.rentersLocation?.state;

  return (
    <CollapsibleCard
      content={
        <>
          <Label
            type="infoSubtitle"
            style={{
              display: 'inline',
              marginRight: 8
            }}
          >{`${item.firstName} ${item.lastName}`}</Label>
          {item.isPrimary ? (
            <Label type="infoCardTitle">Applicant</Label>
          ) : item.isCoApplicant ? (
            <Label type="infoCardTitle">Co applicant</Label>
          ) : null}
        </>
      }
      contentRight={
        <PersonActions
          index={index}
          id={id}
          fieldName={fieldName}
          item={item}
          removeFromList={removeFromList}
          onRemove={onRemove}
          disabled={disabled}
          swapPrimaryApplicant={swapPrimaryApplicant}
          showApplicantSwapButton={showApplicantSwapButton}
          disableSwapButton={disableSwapButton}
          allowLicensedActions={allowLicensedActions}
          fromPolicy={fromPolicy}
          fromHomePolicy
        />
      }
      initialOpen={initialFold}
      onChange={(val) => onFold(index, val)}
    >
      <div className={classNames(classes.container, classes.coApplicantContainer)}>
        <Grid container alignItems="flex-start" justify="space-around" spacing={2}>
          <FormField
            id={`${fieldName}.firstName`}
            name={`${fieldName}.firstName`}
            type="string"
            label="First Name"
            mode="light"
            xs={3}
            disabled={disableNameChange}
          />
          <FormField
            id={`${fieldName}.middleName`}
            name={`${fieldName}.middleName`}
            type="string"
            label="Middle Name"
            mode="light"
            xs={3}
            disabled={disableNameChange}
          />
          <FormField
            id={`${fieldName}.lastName`}
            name={`${fieldName}.lastName`}
            type="string"
            label="Last Name"
            mode="light"
            xs={3}
            disabled={disableNameChange}
          />
          <FormField
            id={`${fieldName}.dateOfBirth`}
            name={`${fieldName}.dateOfBirth`}
            type="date"
            label="Date of Birth"
            mode="light"
            xs={3}
          />
          <FormField
            id={`${fieldName}.suffix`}
            name={`${fieldName}.suffix`}
            type="string"
            label="Suffix"
            mode="light"
            xs={2}
          />
          {!hideGenderStates.includes(state) && (
            <FormField
              id={`${fieldName}.gender`}
              name={`${fieldName}.gender`}
              type="select"
              label="Gender"
              mode="light"
              options={lookupsJson.gender}
              xs={2}
              permissions={{ isLicensedAction: false }}
            />
          )}
          <FormField
            id={`${fieldName}.maritalStatus`}
            name={`${fieldName}.maritalStatus`}
            type="select"
            label="Marital Status"
            mode="light"
            options={
              fromPolicy
                ? lookupsJson.maritalStatus
                : lookupsJson.maritalStatus.filter((option) => {
                    return option.id !== 'W';
                  })
            }
            xs={2}
            permissions={{ isLicensedAction: false }}
          />
          {!hideHighestEducationStates.includes(state) && (
            <FormField
              id={`${fieldName}.highestEducation`}
              name={`${fieldName}.highestEducation`}
              type="select"
              label="Highest Education Achieved"
              mode="light"
              options={lookupsJson.highestEducation}
              xs={6}
              permissions={{ isLicensedAction: false }}
            />
          )}
        </Grid>
      </div>
      {(item.isPrimary || item.isCoApplicant) && item.insuranceScore && (
        <Grid container alignItems="center" justify="flex-end" className={classes.cardFooter}>
          <Grid container item xs={3} direction="column" alignItems="flex-end">
            <Label type="infoLabel">Insurance Score</Label>
            <Label type="infoValue">
              {noCreditStates[state]
                ? 'N/A'
                : Number(item.insuranceScore?.homeTotal?.toFixed(1) || item.insuranceScore?.total?.toFixed(1))}
            </Label>
          </Grid>
        </Grid>
      )}
    </CollapsibleCard>
  );
}

Applicant.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string,
    addDate: PropTypes.string,
    ageFirstLicensed: PropTypes.number,
    driversLicenseNumber: PropTypes.string,
    driversLicenseState: PropTypes.string,
    fullTimeStudent: PropTypes.bool,
    gender: PropTypes.string,
    goodStudent: PropTypes.bool,
    highestEducation: PropTypes.string,
    isCoApplicant: PropTypes.bool,
    isPrimary: PropTypes.bool,
    maritalStatus: PropTypes.string,
    schoolLocation: PropTypes.object,
    schoolName: PropTypes.string,
    suffix: PropTypes.string,
    insuranceScore: PropTypes.object
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired,
  initialFold: PropTypes.bool.isRequired,
  onFold: PropTypes.func.isRequired,
  swapPrimaryApplicant: PropTypes.func.isRequired,
  showApplicantSwapButton: PropTypes.bool.isRequired,
  disableSwapButton: PropTypes.bool.isRequired,
  disableNameChange: PropTypes.bool.isRequired,
  fromPolicy: PropTypes.bool
};

Applicant.defaultProps = {
  disabled: false,
  fromPolicy: false
};

export default withDatePicker(Applicant);
