import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { getValue } from '@ourbranch/lookups';

import { Button } from 'core/components/button';
import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import NotificationType from 'core/helpers/notifications';
import { useStore } from 'core/store';
import { NotificationCard } from 'core/components/notification-card';
import useStyles from './notification.styles';

const notificationsToFilterOutAtCheckout = [
  NotificationType.Offer.OfferNeedsUWReviewForHome,
  NotificationType.Offer.PriorQuoteWithSelfReportedHomeUWFlags
];

const AbodeProPlan = ({ classes }) => (
  <Label className={classes.styledText}>
    {`This offer was generated using a special link that includes `}
    <strong>{`an additional $100 charge for the Abode Pro plan.`}</strong>
    {` This charge is separate from your premium and must be paid by credit card. If you do not want the Abode Pro Plan included, please turn off the
        Connected Home discount in the Settings tab and update the offer.`}
  </Label>
);

const NewConnectedHomeSignUpCustomer = ({ classes }) => (
  <Label className={classes.styledText}>
    {`A new sign up will prompt the home security company to contact the customer to buy the home security system necessary to retain the Connected Home Discount. Available with select home security partnerships only.`}
  </Label>
);

const ConnectedHome = ({ classes }) => (
  <Label className={classes.styledText}>
    {`This offer has an affinity discount from a Connected Home partner, but does not include a Connected Home discount,
        resulting in an incorrect price. To correct this, go to the Settings tab and `}
    <strong>{`make sure the Connected Home discount is turned on and has devices
        added, or remove the affinity discount.`}</strong>
  </Label>
);

const OfferNeedsUWReviewForHome = () => (
  <Label>
    {`Branch needs additional confirmations before home policy eligibility can be confirmed. Please continue to the Underwriting Review page to verify.`}
  </Label>
);

const LapsedDriversLicenses = ({ state }) => {
  const stateValue = getValue('usStates', state);

  return (
    <Label>
      This is a {stateValue} Offer. Please ensure the age first licensed and driver's license lapse status is accurate
      for all drivers before continuing.
    </Label>
  );
};

const PriorQuoteWithSelfReportedHomeUWFlags = ({ classes, canClearUWFormRejections, setShowUWResetModal }) => (
  <Grid container className={classes.UWContainer}>
    <Label>
      During this or a prior sales underwriting review, this member reported a home characteristic that does not meet
      Branch underwriting guidelines.
      {canClearUWFormRejections && (
        <Button
          className={classes.verifyBtn}
          variant="contained"
          color="primary"
          onClick={() => setShowUWResetModal(true)}
        >
          Reset
        </Button>
      )}
    </Label>
  </Grid>
);

const OfferNotifications = observer(({ type, setShowUWResetModal }) => {
  const { offer: offerStore } = useStore();
  const { canClearUWFormRejections } = useContext(AuthContext);
  const classes = useStyles();
  const location = useLocation();

  const atCheckout = location.pathname.includes('checkout');

  let notifications = offerStore.notifications;

  if (atCheckout) {
    notifications = notifications.filter(
      (notificationType) => !notificationsToFilterOutAtCheckout.includes(notificationType)
    );
  }

  return (
    <Grid id="notificationsContainer">
      {notifications.map((notificationType) => (
        <NotificationCard key={notificationType} type={type}>
          {notificationType === NotificationType.Offer.AbodeProPlan && <AbodeProPlan classes={classes} />}
          {notificationType === NotificationType.Offer.ConnectedHome && <ConnectedHome classes={classes} />}
          {notificationType === NotificationType.Offer.NewConnectedHomeSignUpCustomer && (
            <NewConnectedHomeSignUpCustomer classes={classes} />
          )}
          {notificationType === NotificationType.Offer.OfferNeedsUWReviewForHome && <OfferNeedsUWReviewForHome />}
          {notificationType === NotificationType.Offer.PriorQuoteWithSelfReportedHomeUWFlags && (
            <PriorQuoteWithSelfReportedHomeUWFlags
              classes={classes}
              canClearUWFormRejections={canClearUWFormRejections}
              setShowUWResetModal={setShowUWResetModal}
            />
          )}
          {notificationType === NotificationType.Offer.LapsedDriversLicenses && (
            <LapsedDriversLicenses state={offerStore.state} />
          )}
        </NotificationCard>
      ))}
    </Grid>
  );
});

export default OfferNotifications;
