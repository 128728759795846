import React, { memo, useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import {
  getOptions,
  windstormMitigationDiscountStates,
  stormShuttersStates,
  enhancedRoofWindstormStates,
  enhancedRoofWindstormValues,
  windstormMitigationLevelValues,
  windstormMitigationDiscountYearBuilt,
  windstormMitigationDefinitionsLinkStates
} from '@ourbranch/lookups';

import { NotificationCard } from 'core/components/notification-card';
import Field from 'core/components/form/form.v2';
import { Label } from 'core/components/label';
import { LabelTooltip } from 'core/components/label-tooltip';
import { useCurrentState } from 'common/hooks/useCurrentState';
import { AdvancedWindstormInfoModal } from '../advanced-windstorm-info-modal';
import useStyles from './advanced-windstorm-details.styles';

const id = 'home';

const AdvancedWindstormDetails = () => {
  const classes = useStyles();
  const [displayAdvancedWindstormDetailsInfoModal, setDisplayAdvancedWindstormDetailsInfoModal] = useState({
    show: false,
    name: '',
    options: null
  });

  const { values } = useFormikContext();
  const { home } = values;
  const state = useCurrentState(values);
  const windstormMitigationLevelOptions = getOptions('windstormMitigationLevel', state, windstormMitigationLevelValues);
  const showStormShutters = stormShuttersStates.includes(home.homeLocation.state);
  const showEnhancedRoofWindstorm = enhancedRoofWindstormStates[state];
  const showWindstormMitigationLevel =
    windstormMitigationDiscountStates[state] &&
    (!home.yearBuilt || home.yearBuilt < windstormMitigationDiscountYearBuilt);

  const onTooltipClick = useCallback(
    (name) => {
      const options =
        name === 'windstormMitigationLevel'
          ? windstormMitigationLevelOptions
          : getOptions(name, null, enhancedRoofWindstormValues);
      const definitionsLink =
        name === 'windstormMitigationLevel' && windstormMitigationDefinitionsLinkStates[state]
          ? windstormMitigationDefinitionsLinkStates[state]
          : null;
      setDisplayAdvancedWindstormDetailsInfoModal({ show: true, name, options, definitionsLink });
    },
    [windstormMitigationLevelOptions, getOptions, state]
  );

  return (
    <Grid item xs={12} container alignItems="flex-start" className={classes.container}>
      <Grid item xs={12}>
        <Label type="formSubTitleLight">Advanced Windstorm Details</Label>
      </Grid>
      <NotificationCard type="light">
        <Grid container>
          <b>NOTE</b> The advanced windstorm section requires a detailed knowledge of the construction methods used on
          your home. Proof of these construction methods will be required post-purchase. Your premium could be impacted
          if your documentation does not match the home construction selections made here.
        </Grid>
      </NotificationCard>
      {showEnhancedRoofWindstorm && (
        <>
          <Grid className={classes.gridItem} item xs={6}>
            <LabelTooltip label="Roof Deck" mode="light" onClick={() => onTooltipClick('roofDeck')}>
              <Field
                name={`${id}.roofDeck`}
                type="select"
                mode="light"
                className={classes.field}
                options={enhancedRoofWindstormValues.roofDeck}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
          <Grid className={classes.gridItem} item xs={6}>
            <LabelTooltip label="Roof Cover" mode="light" onClick={() => onTooltipClick('roofCover')}>
              <Field
                name={`${id}.roofCover`}
                type="select"
                mode="light"
                className={classes.field}
                options={enhancedRoofWindstormValues.roofCover}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
          <Grid className={classes.gridItem} item xs={6}>
            <LabelTooltip
              label="Roof Deck Attachment"
              mode="light"
              onClick={() => onTooltipClick('roofDeckAttachment')}
              showDefaultValue
            >
              <Field
                name={`${id}.roofDeckAttachment`}
                type="select"
                mode="light"
                className={classes.field}
                options={enhancedRoofWindstormValues.roofDeckAttachment}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
          <Grid className={classes.gridItem} item xs={6}>
            <LabelTooltip
              label="Roof To Wall Connection"
              mode="light"
              onClick={() => onTooltipClick('roofToWallConnection')}
            >
              <Field
                name={`${id}.roofToWallConnection`}
                type="select"
                mode="light"
                className={classes.field}
                options={enhancedRoofWindstormValues.roofToWallConnection}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
          <Grid className={classes.gridItem} item xs={6}>
            <LabelTooltip
              label="Window Opening Protection"
              mode="light"
              onClick={() => onTooltipClick('openingProtection')}
            >
              <Field
                name={`${id}.openingProtection`}
                type="select"
                mode="light"
                className={classes.field}
                options={enhancedRoofWindstormValues.openingProtection}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
          <Grid className={classes.gridItem} item xs={6}>
            <LabelTooltip label="Secondary Water Resistance" mode="light" onClick={() => onTooltipClick('roofSWR')}>
              <Field
                name={`${id}.roofSWR`}
                type="select"
                mode="light"
                className={classes.field}
                options={enhancedRoofWindstormValues.roofSWR}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
        </>
      )}
      {showStormShutters && (
        <Field
          name={`${id}.stormShutters`}
          type="checkbox"
          label="Do all windows and glass doors have storm shutters available in the event of a windstorm?"
          tooltipText="All openings of your home must have storm shutters, or be engineered to specifications that provide equivalent protection. After you purchase, you will be asked to submit a proof-of-protection form, signed by a qualified inspector and notarized."
          tooltipLabel="More Info"
          mode="light"
          xs={12}
          className={classes.checkbox}
        />
      )}
      {showWindstormMitigationLevel && (
        <Grid className={classes.gridItem} item xs={12}>
          <LabelTooltip
            label="Windstorm Loss Retrofit Level"
            mode="light"
            onClick={() => onTooltipClick('windstormMitigationLevel')}
          >
            <Field
              name={`${id}.windstormMitigationLevel`}
              className={classes.field}
              type="select"
              mode="light"
              options={windstormMitigationLevelOptions}
              showDefaultValue
            />
          </LabelTooltip>
        </Grid>
      )}
      {displayAdvancedWindstormDetailsInfoModal.show && (
        <AdvancedWindstormInfoModal
          open={displayAdvancedWindstormDetailsInfoModal.show}
          name={displayAdvancedWindstormDetailsInfoModal.name}
          onClose={() => setDisplayAdvancedWindstormDetailsInfoModal({ show: false })}
          options={displayAdvancedWindstormDetailsInfoModal.options}
          definitionsLink={displayAdvancedWindstormDetailsInfoModal.definitionsLink}
        />
      )}
    </Grid>
  );
};

export default memo(AdvancedWindstormDetails);
