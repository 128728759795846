import React, { useEffect, useCallback, useMemo } from 'react';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {
  getOptions,
  mineSubsidenceRequired,
  setState,
  mandatoryWindHailDeductibleByCounty,
  mandatoryWindHailDeductibleByZip,
  hurricaneDeductibleStates,
  limitedBranchWindhailCoverage,
  windHailDeductibleCanVaryByCounty,
  noWindHailDeductibleStates,
  coverageDNotOffered,
  ibscDescriptionByState,
  restrictedBLimitsStates,
  restrictedCLimitsStates,
  restrictedDLimitsStates,
  unlimitedDLimitsStates,
  UNLIMITED_D_VALUE
} from '@ourbranch/lookups';
import { Form, connect } from 'formik';

import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { useCurrentState } from 'common/hooks/useCurrentState';
import Field from 'core/components/form/form.v2';
import { Label } from 'core/components/label';
import { LabelTooltip } from 'core/components/label-tooltip';
import Section from 'core/components/section';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { numberThousandsFormatter } from 'core/helpers/formatters';
import OtherCoverages from './other-coverages';
import WindHailFlow from './wind-hail-flow';
import styles from '../styles';

const id = 'homeCoverage';

const buildOptions = (value, letter, state, numFamiliesInDwelling) => {
  let min = 0;
  let max = 100;
  // eslint-disable-next-line default-case
  switch (letter) {
    case 'B':
      min = 0;
      if (restrictedBLimitsStates.includes(state)) {
        max = 10;
      } else {
        max = 100;
      }
      break;
    case 'C':
      min = 0;
      if (restrictedCLimitsStates.includes(state)) {
        if (!numFamiliesInDwelling || numFamiliesInDwelling <= 2) {
          max = 60;
        } else if (numFamiliesInDwelling === 3) {
          max = 40;
        } else {
          max = 35;
        }
      } else {
        max = 100;
      }
      break;
    case 'D':
      if (restrictedDLimitsStates.includes(state)) {
        // these states fix ALE (CovD) at 10% of CovA
        min = 10;
        max = 10;
      } else {
        min = 0;
        max = 40;
      }
      break;
  }
  const array = [];
  for (let num = min; num <= max; num += 5) {
    array.push({ id: num, value: `${num}% ($${((num / 100) * value).toLocaleString('en-US')})` });
  }

  if (letter === 'D' && unlimitedDLimitsStates.includes(state)) {
    array.push({ id: UNLIMITED_D_VALUE, value: 'Unlimited for up to 24 months' });
  }

  return array;
};

function HomeCoverage({
  classes,
  disabled,
  formik: {
    setFieldValue,
    values: { homeCoverage, home, ...formikValues }
  },
  fromPolicy
}) {
  const state = useCurrentState(formikValues);

  const county = home.county.toUpperCase();
  const enforceMS = !!(mineSubsidenceRequired[state] && mineSubsidenceRequired[state][county]);
  const showDeductibleWindHail =
    (!windHailDeductibleCanVaryByCounty[state] ||
      (windHailDeductibleCanVaryByCounty[state] && windHailDeductibleCanVaryByCounty[state][county])) &&
    !noWindHailDeductibleStates[state];

  const showCoverageD = !coverageDNotOffered[state];

  const estimatedCost = `${home.replacementCostEstimate.total.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })}`;

  const { coverageA, windHailExclusion } = homeCoverage;
  const minimumDeductibleValue = homeCoverage.minimumDeductibleValue || 100;
  const minimumWindHailDeductibleValue = homeCoverage.minimumWindHailDeductibleValue || 100;
  const minimumHurricaneDeductibleValue = homeCoverage.minimumHurricaneDeductibleValue || 100;

  const windHailTooltip =
    limitedBranchWindhailCoverage && limitedBranchWindhailCoverage[state] && homeCoverage?.windHailExclusion
      ? 'Branch does not cover for wind or hail damage in this zipcode. This coverage can be purchased through AIUA (https://aiua.org) as an additional purchase.'
      : tooltipHoverTexts.windHail;

  const windHailOptions = getOptions('deductibleWindHail', state);
  const hurricaneOptions = getOptions('deductibleHurricane', state);
  const allOtherOptions = getOptions('deductibleAllOther', state);

  setState(state);

  useEffect(() => {
    if (enforceMS) {
      setFieldValue('homeCoverage.coverageMS', true);
    }
  }, [enforceMS, homeCoverage, setFieldValue]);

  const formatPercentageOptionValue = useCallback(
    (o) => {
      const option = { ...o, value: o.value };
      const value = option.id * coverageA;
      option.value = `${option.value} ($${numberThousandsFormatter(value)})`;
      return option;
    },
    [coverageA]
  );

  const mapDeductible = useCallback(
    (option) => (option.id < 1 ? formatPercentageOptionValue(option) : option),
    [formatPercentageOptionValue]
  );

  const filterLowerThanMinimumDeductible = useCallback(
    (option) => {
      const value = option.id > 1 ? option.id : option.id * coverageA;

      return value >= minimumDeductibleValue;
    },
    [coverageA, minimumDeductibleValue]
  );

  const filterLowerThanMinimumOrWindHailDeductible = useCallback(
    (option) => {
      const value = option.id > 1 ? option.id : option.id * coverageA;
      if (mandatoryWindHailDeductibleByZip[state] || mandatoryWindHailDeductibleByCounty[state]) {
        const MDmin =
          mandatoryWindHailDeductibleByZip?.[state]?.[home.homeLocation.zip] ||
          mandatoryWindHailDeductibleByCounty?.[state]?.[home.county];
        if (MDmin < 1) {
          return (
            value >= minimumDeductibleValue &&
            value >= minimumWindHailDeductibleValue &&
            option.id < 1 &&
            option.id >= MDmin
          );
        }
        return value >= minimumDeductibleValue && value >= minimumWindHailDeductibleValue && value >= MDmin;
      }
      return value >= minimumDeductibleValue && value >= minimumWindHailDeductibleValue;
    },
    [coverageA, minimumDeductibleValue, minimumWindHailDeductibleValue, state, home.county, home.homeLocation.zip]
  );

  const filterLowerThanMinimumOrHurricaneDeductible = useCallback(
    (option) => {
      const value = option.id > 1 ? option.id : option.id * coverageA;
      return value >= minimumDeductibleValue && value >= minimumHurricaneDeductibleValue;
    },
    [coverageA, minimumDeductibleValue, minimumHurricaneDeductibleValue, state, home.county, home.homeLocation.zip]
  );

  const allOtherOptionsFormatted = useMemo(
    () => allOtherOptions.filter(filterLowerThanMinimumDeductible).map(mapDeductible),
    [allOtherOptions, filterLowerThanMinimumDeductible, mapDeductible]
  );

  const windHailOptionsFormatted = useMemo(
    () => windHailOptions.filter(filterLowerThanMinimumOrWindHailDeductible).map(mapDeductible),
    [windHailOptions, filterLowerThanMinimumOrWindHailDeductible, mapDeductible]
  );

  const hurricaneOptionsFormatted = useMemo(
    () => hurricaneOptions.filter(filterLowerThanMinimumOrHurricaneDeductible).map(mapDeductible),
    [hurricaneOptions, mapDeductible]
  );

  return (
    <Section title="Home Policy Coverage" type="SubSection">
      <div className={classes.container}>
        <Form disabled={disabled} key={state}>
          <Grid container justify="flex-start" alignItems="center" spacing={2} className={classes.containerDark}>
            <Grid item xs={12}>
              <Label key="lblTop" type="formSubTitle">
                Dwelling Limit
              </Label>
            </Grid>
            <Grid item xs={6}>
              <Label type="coverageLabel" className={classes.coverageLabel}>
                Estimated Cost to Rebuild Home
              </Label>
              <Label type="coverageAmount" className={classes.coverageAmount}>
                {estimatedCost}
              </Label>
            </Grid>

            <Grid item xs={6}>
              <LabelTooltip
                label="Dwelling Limit"
                tooltip={{
                  label: 'More Info',
                  onHoverText:
                    ibscDescriptionByState[state] ||
                    "We recommend at least 10% Incremental Coverage. This way, if it costs more to rebuild your home, you'll have added peace of mind."
                }}
              >
                <Field
                  id={`${id}.coverageA`}
                  name={`${id}.coverageA`}
                  type="numeric"
                  mode="dark"
                  permissions={{
                    edit: { groups: [CognitoPermissionGroups.isTeamLeader] }
                  }}
                />
              </LabelTooltip>
            </Grid>

            <Grid item xs={12}>
              <Label type="coverageParagraph" className={classes.coverageLabel}>
                Other Coverages Based on Your Dwelling Limit
              </Label>
            </Grid>

            <Grid item xs={4}>
              <LabelTooltip
                label="Personal Property limit"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.personalPropertyLimit }}
              >
                <Field
                  id={`${id}.coverageCPctOfA`}
                  name={`${id}.coverageCPctOfA`}
                  type="select"
                  mode="dark"
                  options={buildOptions(homeCoverage.coverageA, 'C', state, home.numFamiliesInDwelling)}
                />
              </LabelTooltip>
            </Grid>

            {showCoverageD && (
              <Grid item xs={4}>
                <LabelTooltip
                  label="Additional Living Expense"
                  tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.additionalLivingExpense }}
                >
                  <Field
                    id={`${id}.coverageDPctOfA`}
                    name={`${id}.coverageDPctOfA`}
                    type="select"
                    mode="dark"
                    options={buildOptions(homeCoverage.coverageA, 'D', state)}
                  />
                </LabelTooltip>
              </Grid>
            )}

            <Grid item xs={4}>
              <LabelTooltip
                label="Other Structures limit"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.otherStructuresLimit }}
              >
                <Field
                  id={`${id}.coverageBPctOfA`}
                  name={`${id}.coverageBPctOfA`}
                  type="select"
                  mode="dark"
                  options={buildOptions(homeCoverage.coverageA, 'B', state)}
                />
              </LabelTooltip>
            </Grid>

            <Grid container item spacing={2} className={classes.coverageContainer}>
              <Grid item xs={6}>
                <LabelTooltip
                  label="Family Liability"
                  tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.familyLiability }}
                >
                  <Field
                    id={`${id}.coverageX`}
                    name={`${id}.coverageX`}
                    type="select"
                    mode="dark"
                    // how do we change this over to lookupsJson?
                    options={getOptions('coverageX', state)}
                  />
                </LabelTooltip>
              </Grid>
              <Grid item xs={6}>
                <LabelTooltip
                  label="Guest Medical Limit"
                  tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.guestMedicalLimit }}
                >
                  <Field
                    id={`${id}.coverageY`}
                    name={`${id}.coverageY`}
                    type="select"
                    mode="dark"
                    // how do we change this over to lookupsJson?
                    options={getOptions('guestMedicalLimit', state)}
                  />
                </LabelTooltip>
              </Grid>
            </Grid>
            <Grid container item spacing={2} className={classes.coverageContainer}>
              <Grid item xs={12}>
                <Label key="lblTop" type="formSubTitle">
                  Deductible
                </Label>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs>
                  <LabelTooltip
                    label="Deductible"
                    tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.deductibleAllOther }}
                  >
                    <Field
                      id={`${id}.deductibleAllOther`}
                      name={`${id}.deductibleAllOther`}
                      type="select"
                      mode="dark"
                      options={allOtherOptionsFormatted}
                    />
                  </LabelTooltip>
                </Grid>
                {hurricaneDeductibleStates[state] && (
                  <Field
                    id={`${id}.deductibleHurricane`}
                    name={`${id}.deductibleHurricane`}
                    type="select"
                    label="Hurricane deductible"
                    mode="dark"
                    options={hurricaneOptionsFormatted}
                    xs
                  />
                )}
                {showDeductibleWindHail && (
                  <Grid item xs>
                    <LabelTooltip
                      label="Wind & Hail deductible"
                      tooltip={{ label: 'More Info', onHoverText: windHailTooltip }}
                    >
                      <Field
                        id={`${id}.deductibleWindHail`}
                        name={`${id}.deductibleWindHail`}
                        type="select"
                        mode="dark"
                        options={windHailOptionsFormatted}
                        disabled={windHailExclusion}
                      />
                    </LabelTooltip>
                  </Grid>
                )}
              </Grid>
              {windHailExclusion && <WindHailFlow fromPolicy={fromPolicy} />}
            </Grid>
            <OtherCoverages
              id={id}
              home={home}
              enforceMS={enforceMS}
              state={state}
              coverageA={homeCoverage.coverageA}
              fromPolicy={fromPolicy}
            />
          </Grid>
        </Form>
      </div>
    </Section>
  );
}

HomeCoverage.propTypes = {
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fromPolicy: PropTypes.bool
};

HomeCoverage.defaultProps = {
  disabled: false,
  fromPolicy: false
};

export default flowRight(withStyles(styles), connect)(HomeCoverage);
