/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import People from 'common/components/people';
import AddApplicant from 'common/components/people/add-form';
import Applicant from 'common/components/people/applicant';
import { useDisabledState } from 'common/disabled-context';
import Discounts from 'customer/components/policy/discounts';
import { Additionals, HomeCoverage, Umbrella } from './coverages';
import Detail from './detail';
import { SchedulePP } from '../schedule-pp';

const HomePolicy = ({ repEmail }) => {
  const session = useContext(AuthContext);
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();

  const allowLicensedActions = policyStore.getAllowLicensedActions(session);

  const { disabled } = useDisabledState();

  return (
    <>
      <People
        disabled={disabled || !allowLicensedActions}
        id="people"
        title="People"
        person={Applicant}
        addForm={AddApplicant}
        addLabel="Add People"
        fromPolicy
      />
      <Detail disabled={disabled} />
      <HomeCoverage disabled={disabled} fromPolicy />
      <Additionals disabled={disabled} fromPolicy repEmail={repEmail} />
      <Umbrella disabled={disabled} fromPolicy />
      <Discounts disabled={disabled} homeDiscounts />
      <SchedulePP disabled={disabled} />
    </>
  );
};

HomePolicy.propTypes = {
  repEmail: PropTypes.string.isRequired
};

export default observer(HomePolicy);
