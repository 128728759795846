export { templates } from './templates';
export { carsWithPassiveRestraint } from './carsWithPassiveRestraint';
export { policyRebillSchedule } from './policyRebillSchedule';
export { creditVisionAutoReasons } from './creditVisionAutoReasons';
export { trueRiskPropertyReasons } from './trueRiskPropertyReasons';

export const documentTypes = {
  APPLICATION: 0,
  POLICY_JACKET: 1,
  ID_CARDS: 2,
  DECLARATION: 3,
  FCRA_NOTICE: 4,
  CANCELLATION: 5,
  POLICY_MOD_CONFIRMATION: 6,
  RENEWAL: 7,
  CONVERSION: 8,
  UM_NOTICE: 9,
  MEDPAY: 10,
  PIP_NOTICE: 11,
  BIX_CONVERSION_NON_RENEWAL: 12,
  NDEL_NOTICE: 13,
  ESCROW_INVOICE: 14,
  REPLACEMENT_COST_ESTIMATE: 15
};

export const aleTimeLinePerState = {
  CO: 'Up to 24 months not to exceed'
};

export const alternateFRStates = {
  PA: true
};

/** Now getting from the main rater */
export const alternateUMUIMStates = {
  UT: false
};

export const umUimIncludedInPerCarTotal = {
  TX: true
};

export const umpdPerPolicyStates = {
  TX: true
};

export const listExcludedDriversOnIDCardsStates = {
  AR: true,
  MI: true,
  NE: true,
  OK: true,
  PA: true
};

export const separateUMandUIMOnFormsStates = {
  AR: true,
  DC: true,
  IA: true,
  ID: true,
  KY: true,
  MO: true,
  MT: true,
  PA: true,
  SC: true,
  SD: true,
  UT: true,
  VT: true,
  WI: true,
  WV: true
};

export const umOnlyOnLabelStates = {
  GA: true,
  OK: true,
  SD: true,
  TN: true,
  UT: true,
  WY: true
};

/** Friendly names for policy documents */
export const docIdToFriendlyName = {
  A_AMND: 'Auto Policy Amendatory Endorsement',
  A_APPL: 'Auto Application',
  A_BIOF: 'Bodily Injury Option and Premiums',
  A_BISF: 'Choice of Bodily Injury Liability Coverage Limits',
  A_CNPY: 'Cancellation Notice',
  A_CNREL: 'Cancellation Notice',
  A_CNRET: 'Cancellation Notice',
  A_CNREV: 'Cancellation Notice',
  A_NRBIX: 'Insurance Cancellation Notice',
  A_CNRS: 'Cancellation Notice',
  A_CODW: 'Collision Deductible Waiver',
  A_DECK: 'Auto Declarations',
  A_DECLL: 'Declination Notice',
  A_DECLT2: 'Declination Notice',
  A_DECLT: 'Declination Notice',
  A_DECLV: 'Declination Notice',
  A_DITR: 'Declaration of Intent to Reside',
  A_ICSE: 'Insurance Consultation Services Exemption',
  A_IDCD: 'Auto ID Cards',
  A_LOIW: 'Loss of Income Waiver',
  A_MEDP: 'MedPay Selection/Rejection Form',
  A_MPSF: 'Selection of Personal Injury Protection',
  A_NDEL: 'Named Driver Exclusion Election Form',
  A_NDEX: 'Named Driver Exclusion',
  A_NNOP: 'Named Non-Owner Coverage Endorsement',
  A_NOBE: 'Notice of Benefits',
  A_NOTO: 'Notice of Tort Options',
  A_NPRCT: 'Rescission Notice',
  A_NRRS: 'Non-Renewal Notice',
  A_NULL: 'Null Contract',
  A_PIPR: 'Pet Injury Endorsement',
  A_PIPS: 'Notice of Waiver of Personal Injury Protection',
  A_PJAK: 'Auto Policy',
  A_PLCY: 'Auto Policy',
  A_PSDS: 'Surcharge Disclosure',
  A_RENT: 'Renters Endorsement',
  A_RIDE: 'Rideshare Endorsement',
  A_SFSW: 'Auto Stress-Free Switch Letter',
  A_SMCQ: 'State Minimum Coverage Quote',
  A_TOSF: 'Tort Selection Rejection Form',
  A_UIMS: 'UIM Selection Rejection Form',
  A_APTA: 'Automobile Policy Term Acknowledgement Form',
  A_UMNF: 'Notice Regarding UMPD',
  A_UMRJ: 'UM/UIM Rejection Form',
  A_UMSF: 'UM or UM/UIM Selection Rejection Form',
  A_WGPT: 'Window Glass Coverage Endorsement',
  A_WLBW: 'Work Loss Benefits Waiver',
  B_SASP: 'Subscriber Agreement Signature Page',
  B_AUTH: 'Recurring Payment Authorization',
  BRANCH_AUTO_ID_CARDS: 'Auto ID Cards',
  H_AAVK: 'Adverse Action Decision Notice',
  H_AMND: 'Home Policy Amendatory Endorsement',
  H_ANME: 'Animal Liability Exclusion',
  H_ANML: 'Animal Liability Limitation Endorsement',
  H_APPL: 'Homeowners Application',
  H_BIND: 'Home Insurance Proof of Insurance',
  H_CMSL: 'Coal Mine Subsidence Waiver',
  H_CNPY: 'Cancellation Notice',
  H_CNRN: 'Insurance Cancellation Notice',
  H_CNRS: 'Cancellation Notice',
  H_NRBIX: 'Insurance Cancellation Notice',
  H_DECK: 'Homeowners Declarations',
  H_DPUA: 'Homeowners Personal Umbrella Liability Declarations Addendum',
  H_EUSF: 'Excess UM/UIM Motorists Selection/Rejection Form',
  H_FCRA: 'Adverse Action Decision Notice',
  H_IB10: 'Increased Building Structure Protection 10% Endorsement',
  H_MINA: 'Mine Subsidence Insurance Underwriting Association Application',
  H_MNSF: 'Mine Subsidence Selection Rejection Form',
  H_MINE: 'Mine Subsidence Endorsement',
  H_NRREV: 'Non-Renewal Notice',
  H_NRRS: 'Non-Renewal Notice',
  H_NULL: 'Null Contract',
  H_PJAK: 'Homeowners Policy',
  H_PLCY: 'Homeowners Policy',
  H_PUMB: 'Personal Umbrella Liability Endorsement',
  H_RENI: 'Fair Rental Income Endorsement',
  H_ROFE: 'Roof Surfaces Extended Coverage',
  H_ROFM: 'Metal Roof Surfaces Cosmetic Damage Exclusion',
  H_ROOF: 'Roof Surfaces Endorsement',
  H_SHAR: 'Home Data Sharing Authorization',
  H_SFSW: 'Home Stress-Free Switch Letter',
  H_SPPE: 'Scheduled Personal Property ',
  H_WNHD: 'Windstorm and Hail Deductible Endorsement',
  H_WHXX: 'Wind/Hail Exclusion Form',
  H_WTBU: 'Water Backup Endorsement',
  H_INVC: 'Escrow Invoice',
  H_RCEL: 'Home Replacement Cost Estimate Letter',
  R_AMND: 'Renters Policy Amendatory Endorsement',
  R_APPL: 'Renters Application',
  R_CNPY: 'Cancellation Notice',
  R_CNRS: 'Cancellation Notice',
  R_NRRS: 'Non-Renewal Notice',
  R_PLCY: 'Renters Policy',
  R_PJAK: 'Renters Policy',
  R_SPPE: 'Scheduled Personal Property ',
  R_SFSW: 'Renters Stress-Free Switch Letter',
  R_DECK: 'Renters Declarations'
};

/** Descriptive text for whether you're paying up front or monthly */
export const paymentTypeText = {
  I: 'monthly',
  F: 'up front',
  E: 'billed to mortgage lender'
};

export const escrowPaymentMethod = 'W';

export const referral = {
  payoutMethods: {
    manualPayoutId: 'f271c5d0-4c3c-4274-8276-1184b0238111',
    amazonGiftCardPayoutId: 'fd8e8811-61d5-4b9c-9324-7bf90abc0566'
  },
  programs: {
    inventoryUploadProgramId: '44511320-d510-4ef7-897c-b8ef7b08713a',
    referAndEarnProgramId: 'd10f7c85-5aa1-4ef4-a0a9-5ea4f753bba5'
  },
  payoutAmounts: {
    optionalInventorySubmissionPayoutUSD: 10
  }
};

/** At present, the reasons that don't generate a fee: CNCN, CNMV, CNUW, CNDP, CNBX, CNDE */
export const cancelReasonsForFee = {
  CNDC: 1,
  CNPR: 1,
  CNDR: 1,
  CNDB: 1,
  CNMS: 1,
  CNPY: 1,
  CNID: 1,
  CNMV: 1,
  CNNO: 1,
  CNSO: 1,
  NA: 1
};

export const insuredInitiatedCancelReason = {
  CNDC: 1,
  CNPR: 1,
  CNDR: 1,
  CNDB: 1,
  CNMV: 1,
  CNNO: 1,
  CNSO: 1,
  NA: 1
};

export const holdBackOnInsuredCancelStates = {
  VA: 0.1
};

export const maximumNewHomeDiscountHomeAgeByState = {
  ME: 49,
  NH: 49,
  SD: 49,
  VT: 49
};

// cancel fees apply to both cancellations and reinstatements
export const cancelFeeStates = {
  AL: true,
  AR: true,
  AZ: true,
  CO: true,
  DC: true,
  GA: true,
  IA: true,
  ID: true,
  IL: false,
  IN: true,
  KS: true,
  KY: true,
  MD: true,
  ME: true,
  MI: false,
  MO: true,
  MS: true,
  MT: true,
  ND: false,
  NE: true,
  NH: false,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  SC: true,
  SD: false,
  TN: true,
  TX: false,
  UT: true,
  VA: true,
  VT: true,
  WI: true,
  WV: false,
  WY: true
};

export const nonBixPolicyFeeStates = {
  AZ: true,
  MO: true,
  IL: true,
  OH: true,
  TX: true
};

export const nonBixImageryFeeStates = {
  AZ: true,
  MO: true,
  IL: true,
  OH: true,
  TX: true
};

export const medpayFormRegenerateStates = {
  CO: true
};

export const activeStatus = 'ActiveStatus';
export const startedLoggingReceivedCVData = '2019-10-01';
/** Append with a clarion door id */
export const clarionDoorURL = 'https://gobranch.insuredapp.com/rating/prod-requests/';

// these ignore certain codes in the auto-mapper for certain things
export const arkansasRulesNoRateCodes = {
  AR: {
    NAF: true
  }
};

// these ignore certain codes in the auto-mapper
export const noRateCodesByState = {
  AZ: {
    startDateAsInt: 20210201,
    NAF: true,
    CMP: true,
    PUA: true,
    PUN: true,
    CMU: true
  },
  IA: {
    startDateAsInt: 20210201,
    NAF: true,
    CMP: true,
    CMU: true
  },
  KS: {
    startDateAsInt: 20180101,
    NAF: true
  },
  MO: {
    startDateAsInt: 20180101,
    NAF: true,
    CMP: true
  },
  MT: {
    startDateAsInt: 20180101,
    CMP: true,
    NAF: true,
    UDR: true
  },
  TN: {
    startDateAsInt: 20180101,
    CMP: true,
    CMU: true
  },
  TX: {
    startDateAsInt: 20180101,
    PUA: true,
    PUN: true,
    CMP: true,
    CMU: true
  },
  UT: {
    stateDateAsInt: 20210101,
    NAF: true,
    CMP: true
  },
  VT: {
    startDateAsInt: 20180101,
    PUA: true,
    PUN: true,
    CMP: true,
    CMU: true
  },
  WY: {
    startDateAsInt: 20180101,
    CMP: true,
    CMU: true
  }
};

export const safeDrivingViolationsByState = {
  AZ: {
    startDateAsInt: 20180101,
    violations: ['AAF', 'MAJ', 'MIN', 'SPD', 'DWI']
  },
  MO: { startDateAsInt: 20180101, violations: ['AAF', 'MAJ', 'MIN', 'SPD', 'DWI'] },
  ND: { startDateAsInt: 20180101, violations: ['AAF', 'MAJ', 'MIN', 'SPD', 'DWI', 'NAF', 'PUA', 'PUN'] }
};

export const drivingViolationsByState = {
  TX: ['DWI', 'MAJ']
};

export const creditThresholdStates = {
  AL: { A: 283, H: 231 },
  AR: { A: 283, H: 231 },
  AZ: { A: 385, H: 415 },
  CO: { A: 283, H: 218 },
  DC: { A: 283, H: 231 },
  FL: { A: 283, H: 231 },
  GA: { A: 283, H: 231 },
  IA: { A: 283, H: 231 },
  ID: { A: 283, H: 231 },
  IL: { A: 283, H: 231 },
  IN: { A: 283, H: 231 },
  KS: { A: 825, H: 776 },
  KY: { A: 283, H: 231 },
  MA: { A: 283, H: 999 },
  MD: { A: 283, H: 999 },
  ME: { A: 283, H: 305 },
  MI: { A: 283, H: 231 },
  MO: { A: 385, H: 415 },
  MS: { A: 283, H: 231 },
  MT: { A: 283, H: 231 },
  ND: { A: 283, H: 305 },
  NE: { A: 283, H: 231 },
  NH: { A: 283, H: 305 },
  NM: { A: 283, H: 231 },
  OH: { A: 283, H: 231 },
  OK: { A: 283, H: 231 },
  OR: { A: 283, H: 231 },
  PA: { A: 283, H: 231 },
  SC: { A: 825, H: 776 },
  SD: { A: 283, H: 305 },
  TN: { A: 283, H: 231 },
  TX: { A: 510, H: 472 },
  UT: { A: 283, H: 231 },
  VA: { A: 283, H: 218 },
  VT: { A: 283, H: 305 },
  WI: { A: 283, H: 231 },
  WV: { A: 283, H: 231 },
  WY: { A: 283, H: 231 }
};

export const notifyMediaAlphaEvents = {
  /** Succesfully rated for a policy */
  REJECT_NO_CODE: 1,
  /** Succesfully bound a policy */
  BIND: 2,
  /** Rejected for a policy */
  REJECT_WITH_CODE: 3
};

export const raterBlacklist = [
  {
    fname: 'kelly', // all lowercase
    lname: 'carver',
    address: '602 harris st',
    city: 'kent',
    state: 'OH'
  },
  {
    fname: 'chris', // all lowercase
    lname: 'bezucha',
    address: '207 s mobberly ave',
    city: 'longview',
    state: 'TX'
  },
  {
    fname: 'bayron', // all lowercase
    lname: 'reyes',
    address: '8300 sheridan blvd',
    city: 'westminister',
    state: 'CO'
  },
  {
    fname: 'bayron', // all lowercase
    lname: 'reyes martinez',
    address: '7903 lowell blvd',
    city: 'westminister',
    state: 'CO'
  },
  {
    fname: 'manuel', // all lowercase
    lname: 'bay',
    address: '2401 rountree dr',
    city: 'bryan',
    state: 'TX'
  },
  {
    fname: 'megan', // all lowercase
    lname: 'coffey',
    address: '946 Ruma Rd',
    city: 'columbus',
    state: 'OH'
  }
];

export const homeClaimMapping = {
  /** O means we don't count it, but leaving here so we know we mapped it */
  'Additional Living Expenses': 'O',
  DPctOfA: 'O',
  'PROPERTY DAMAGE': 'E',
  PD: 'E',
  BI: 'E',
  'BODILY INJURY': 'E',
  BPctOfA: 'E',
  'Building Damage': 'E',
  'Business Interruption': 'E',
  'Contents Damage': 'E',
  CPctOfA: 'E',
  Dwelling: 'E',
  'Household Goods': 'E',
  'Leasehold/Rental': 'E',
  MEDPAY: 'O',
  'Med Pay': 'O',
  Medical: 'O',
  MP: 'O',
  Other: 'F',
  OTHER: 'F',
  'Personal Property': 'E',
  'Residential Property': 'E',
  'FIRE OR LIGHTNING': 'D',
  'WIND AND HAIL': 'F',
  'EXPLOSION OR IMPLOSION': 'D',
  'RIOT OF CIVIL COMMOTION': 'F',
  'DAMAGE BY VEHICLES': 'F',
  SMOKE: 'D',
  VANDALISM: 'E',
  'GLASS BREAKAGE': 'F',
  'CRIME (BURGLARY, THEFT)': 'E',
  DERAILMENT: 'F',
  'WATER DAMAGE': 'G',
  WaterBackup: 'G',
  'FALLING OBJECTS': 'F',
  'WEIGHT OF ICE/SNOW/SLEET': 'G',
  COLLAPSE: 'F',
  'ELECTRIC CURRENTS': 'F',
  'SONIC BOOM': 'F',
  'EARTHQUAKE, VOLCANO': 'O',
  'ENVIRONMENTAL CONTAMINATE': 'F',
  'MUDSLIDE / EARTH MOVEMENT': 'O',
  'MECHANICAL BREAKDOWN': 'F',
  'LOSS ASSESSMENT': 'F',
  'OFF-PREMISES POWER INTERRUPTION': 'F',
  'OFF PREMISES POWER INTER': 'F'
};

// For our Branch claims
export const homeClaimActOfGod = {
  /** O means we don't count it, but leaving here so we know we mapped it */
  'WIND AND HAIL': true,
  'WEIGHT OF ICE/SNOW/SLEET': true,
  'EARTHQUAKE, VOLCANO': true,
  'MUDSLIDE / EARTH MOVEMENT': true
};

export const homeClaimWindHailLightning = {
  /** Need to classify for some states */
  'WIND AND HAIL': true,
  'FIRE OR LIGHTNING': true
};

export const surplusContributionMultiplier = 0.02;
/** Append with a stripe customer id */
export const stripeCustomerUrl = 'https://dashboard.stripe.com/customers/';

export const executionSummaryMapping = {
  rejectionType: {
    HOME: 'home',
    AUTO: 'auto',
    BOTH: 'both',
    NONE: 'none'
  }
};

export const fullStateNamesToAbbreviations: { [index: string]: string } = {
  ALABAMA: 'AL',
  ALASKA: 'AK',
  ARIZONA: 'AZ',
  ARKANSAS: 'AR',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  DELAWARE: 'DE',
  'DISTRICT OF COLUMBIA': 'DC',
  FLORIDA: 'FL',
  GEORGIA: 'GA',
  HAWAII: 'HI',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  IOWA: 'IA',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MAINE: 'ME',
  MARYLAND: 'MD',
  MASSACHUSETTS: 'MA',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSISSIPPI: 'MS',
  MISSOURI: 'MO',
  MONTANA: 'MT',
  NEBRASKA: 'NE',
  NEVADA: 'NV',
  'NEW HAMPSHIRE': 'NH',
  'NEW JERSEY': 'NJ',
  'NEW MEXICO': 'NM',
  'NEW YORK': 'NY',
  'NORTH CAROLINA': 'NC',
  'NORTH DAKOTA': 'ND',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  'RHODE ISLAND': 'RI',
  'SOUTH CAROLINA': 'SC',
  'SOUTH DAKOTA': 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VERMONT: 'VT',
  VIRGINIA: 'VA',
  WASHINGTON: 'WA',
  'WEST VIRGINIA': 'WV',
  WISCONSIN: 'WI',
  WYOMING: 'WY'
};

export const slackChannels = {
  PARTNER_ERRORS: 'partner-errors',
  PARTNER_AGENCIES: 'partner-agencies',
  LETS_TALK_ERRORS: '4001-errors',
  DEV_TESTING: 'dev-testing',
  PARTNER_AGENCY_ERRORS: 'partner-agency-errors',
  SUSPICIOUS_QUOTES: '5019-errors'
};

// Prices provided by Product Manager Matt Hewitt on February 17 2022.
export const umbrellaCoveragePriceByState = {
  AL: { Price: '248.81' },
  AR: { Price: '248.81' },
  AZ: { Price: '130.00' },
  CO: { Price: '212.43' },
  DC: { Price: '212.43' },
  GA: { Price: '229.16' },
  IA: { Price: '156.98' },
  ID: { Price: '186.97' },
  IL: { Price: '184.49' },
  IN: { Price: '237.89' },
  KS: null,
  KY: { Price: '313.55' },
  MD: { Price: '256.08' },
  ME: { Price: '175.33' },
  MI: { Price: '175.33' },
  MO: { Price: '159.43' },
  MS: { Price: '212.43' },
  MT: { Price: '156.98' },
  ND: { Price: '130.00' },
  NE: { Price: '236.44' },
  NH: { Price: '230.76' },
  NM: { Price: '217.52' },
  OH: { Price: '156.98' },
  OK: { Price: '248.08' },
  OR: { Price: '211.70' },
  PA: { Price: '222.62' },
  SC: { Price: '214.61' },
  SD: { Price: '156.98' },
  TN: { Price: '229.16' },
  TX: { Price: '186.00' },
  UT: { Price: '258.26' },
  VA: { Price: '301.91' },
  VT: { Price: '305.62' },
  WI: { Price: '212.43' },
  WV: null
};
