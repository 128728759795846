import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(0, 6, 6, 8)
  },
  gridItem: {
    paddingTop: theme.spacing(4)
  },
  field: {
    minHeight: 'unset'
  },
  checkbox: {
    marginTop: theme.spacing(4)
  }
});

export default makeStyles(styles);
