interface AgencyRecord {
  hash: string;
  agency: boolean;
  informationId: string;
  name: string;
  disableChat: boolean;
  allowRestricted: any;
}

interface AgencyRecords {
  [key: string]: AgencyRecord | undefined;
}

function makeManyCodes(
  prefix: string,
  namePrefix: string,
  count: number,
  disableChat: boolean,
  informationId?: string,
  restrictedStates?: [string]
): AgencyRecords {
  const codes: Partial<AgencyRecords> = {};
  const allowRestricted: Partial<any> = {};
  if (restrictedStates && restrictedStates.length > 0) {
    for (const state of restrictedStates) {
      allowRestricted[state] = true;
    }
  }
  for (let i = 1; i <= count; i += 1) {
    codes[`${prefix}${i}`] = {
      hash: `macgf${i}${prefix}`,
      agency: true,
      informationId: informationId || `${prefix}${i}`,
      name: `${namePrefix} ${i}`,
      disableChat,
      allowRestricted
    };
  }
  return codes;
}

// NOTE: Do not put hyphens in descriptions (the second parameters to makeManyCodes)
// it messes with the (delicate) way that we pull the codes out in staff.
// underscores are intentional, do not remove!
export default {
  ...makeManyCodes('AA', 'Agents Alliance', 25, true),
  ...makeManyCodes('AA0001_', 'All American Insurance Services', 5, true),
  ...makeManyCodes('AB0004_', 'Abacus Insurance and Financial Services, LLC', 1, true),
  ...makeManyCodes('AC0004_', 'Acoma Insurance Professionals, LLC', 2, true),
  ...makeManyCodes('ACR0001_', 'Acrisure, LLC dba Frates Irwin Risk Management Solutions', 2, true),
  ...makeManyCodes('AD0006_', 'Advanced Risk Management, LLC.', 3, true),
  ...makeManyCodes('AIA0001_', 'Alabama Insurance Agency, Inc.', 30, true),
  ...makeManyCodes('AIB0001_', 'Atlas Insurance Brokers, Inc.', 50, true),
  ...makeManyCodes('ADIG', 'Advisor Insurance Group', 1, true),
  ...makeManyCodes('AD0002_', 'Addison Insurance Group', 3, true),
  ...makeManyCodes('AD0007_', 'Advantage Insurance Agency, Inc.', 2, true),
  ...makeManyCodes('AGHRO', 'Agent Hero', 500, true),
  ...makeManyCodes('AGHRO465_', 'Hagenston Insurance Agency', 10, true),
  ...makeManyCodes('AGHRO466_', 'Jos. Cacciatore & Co. Insurance Agency Inc.', 10, true),
  ...makeManyCodes('AGHRO469_', 'Gautam Insurance Agency LLC.', 10, true),
  ...makeManyCodes('AGHRO474_', 'George Jacob', 10, true),
  ...makeManyCodes('AGHRO475_', 'The Smith Firm & Agency', 10, true),
  ...makeManyCodes('AGHRO476_', 'Kohlhase Insurance', 10, true),
  ...makeManyCodes('AGHRO481_', 'Rickey Squires DBA Rickey Squires Agency', 10, true),
  ...makeManyCodes('AGHRO484_', 'Interfirst Insurance Group LLC', 15, true),
  ...makeManyCodes('AGHRO485_', 'The Klein Agency', 10, true),
  ...makeManyCodes('AGHRO487_', 'THE J DAVIS AGENCY', 1, true),
  ...makeManyCodes('AGHRO488_', 'BMC Insurance', 5, true),
  ...makeManyCodes('AGHRO489_', 'Captial Keep', 1, true),
  ...makeManyCodes('AGHRO490_', 'US AMERICA INSURANCE AGENCY INC', 1, true),
  ...makeManyCodes('AGHRO491_', 'DANIEL TODD AGENCY', 1, true),
  ...makeManyCodes('AGHRO492_', 'Calibre Insurance Advisors LLC', 1, true),
  ...makeManyCodes('AGHRO493_', 'Clem Insurance Group', 1, true),
  ...makeManyCodes('AGHRO494_', 'American Insurance specialist LLC', 2, true),
  ...makeManyCodes('AGHRO495_', 'THE DANIELS INSURANCE GROUP', 1, true),
  ...makeManyCodes('AGHRO496_', 'R PEREZ INSURANCE AGENCY LLC', 1, true),
  ...makeManyCodes('AGHRO497_', 'ABOVE Asset Protection LLC', 1, true),
  ...makeManyCodes('AGHRO498_', 'Valencia Insurance Agency', 1, true),
  ...makeManyCodes('AGHRO499_', 'FreeVIPQuote Insurance Agency', 1, true),
  ...makeManyCodes('AGHRO500_', 'Midtown Coverage', 1, true),
  ...makeManyCodes('AGHRO501_', 'James J Sassa Ins Agency Inc', 1, true),
  ...makeManyCodes('AGHRO502_', 'Common Carrier Insurance Agency LLC', 1, true),
  ...makeManyCodes('AGHRO503_', 'INSURANCE GENIE LLC', 1, true),
  ...makeManyCodes('AGHRO504_', 'PMI INSURANCE AGENCY LLC', 1, true),
  ...makeManyCodes('AGHRO505_', 'ACG Services LLC', 5, true),
  ...makeManyCodes('AGHRO506_', 'GUARDED AGENCY', 2, true),
  ...makeManyCodes('AGHRO507_', 'One Hill Insurance Agency', 1, true),
  ...makeManyCodes('AGHRO508_', 'Valley Insurance Consultants LLC', 3, true),
  ...makeManyCodes('AGHRO509_', 'INS~R LLC', 1, true),
  ...makeManyCodes('AGHRO510_', 'We Care Insurance', 1, true),
  ...makeManyCodes('AGHRO511_', 'Cliften Batty Agency', 1, true),
  ...makeManyCodes('AGHRO512_', 'Nathan Washington', 1, true),
  ...makeManyCodes('AGHRO513_', 'BK Insurance & Financial Services, Inc.', 3, true),
  ...makeManyCodes('AHG0001_', 'Auto Home Group', 3, true),
  ...makeManyCodes('AI0001_', 'AIC Insurance Agency, LLC', 10, true),
  ...makeManyCodes('AIB0001_', 'Atlas Insurance Brokers', 50, true),
  ...makeManyCodes('AL0022_', 'Wilcock Insurance Group LLC', 4, true),
  ...makeManyCodes('AL0033_', 'Total Insurance Solutions', 10, true),
  ...makeManyCodes('AL0034_', 'Blevins Insurance Group', 3, true),
  ...makeManyCodes('AL0036_', 'Luis Sarmiento Agency LLC', 2, true),
  ...makeManyCodes('AL0037_', 'AML Insurance Services, LLC', 10, true),
  ...makeManyCodes('AL0038_', 'Advocate Insurance Agency LLC', 10, true),
  ...makeManyCodes('AL0039_', 'Altrui Insurance Partners, LLC', 10, true),
  ...makeManyCodes('AL0040_', 'Zamora Insurance and Financial Services LLC', 10, true),
  ...makeManyCodes('AL0041_', 'J Sequeira Agency Inc. dba Bravura Brokerage', 10, true),
  ...makeManyCodes('AL0042_', 'Verde Valley Insurance', 10, true),
  ...makeManyCodes('AL0043_', 'Bryant Insurance Agency, LLC', 10, true),
  ...makeManyCodes('AL0045_', 'Rachel Walter Agency, Inc DBA Bedrock Insurance Group', 4, true),
  ...makeManyCodes('AL0047_', 'Alliance Insurance Partners', 10, true),
  ...makeManyCodes('AL0051_', 'Family First Insurance Agency LLC', 5, true),
  ...makeManyCodes('AL0053_', 'Choice Insurance Brokers Inc', 1, true),
  ...makeManyCodes('AL0054_', 'Altasurance Alabama', 7, true),
  ...makeManyCodes('AL0055_', 'All Choice Insurance Group', 8, true),
  ...makeManyCodes('AL0056_', 'Aloha Insurance LLc', 4, true),
  ...makeManyCodes('AL0057_', 'Allegiant Risk Management', 2, true),
  ...makeManyCodes('AL0058_', 'All East Valley Insurance', 1, true),
  ...makeManyCodes('AL0059_', 'Burch and Liles Insurance Solutions, LLC', 1, true),
  ...makeManyCodes('AL0060_', 'Everything Insurance group LLC', 1, true),
  ...makeManyCodes('AL0061_', 'Bator Insurance Group LLC', 1, true),
  ...makeManyCodes('AL0062_', 'Stoiloff Insurance Agency', 1, true),
  ...makeManyCodes('AL0063_', 'Tagge Rutherford Property and Casualty', 1, true),
  ...makeManyCodes('AL0064_', 'Kreisman Insurance Group', 1, true),
  ...makeManyCodes('AL0065_', 'Gary Audi Agency Inc', 2, true),
  ...makeManyCodes('AL0066_', 'Affordable Insurance Solutions', 1, true),
  ...makeManyCodes('AL0067_', 'Omaha Agency Services LLC DBA Rensing Insurance', 1, true),
  ...makeManyCodes('AN0001_', 'Andrew Schoch Agency, LLC', 3, true),
  ...makeManyCodes('AM0002_', 'Amana Insurance Agency', 3, true),
  ...makeManyCodes('AM0003_', 'American Integrity Insurance LLC', 3, true),
  ...makeManyCodes('AM0004_', 'America Insurance Brokers, Inc.', 3, true),
  ...makeManyCodes('AM0005_', 'Amity Insurance Group L.L.C.', 6, true),
  ...makeManyCodes('AMS', 'AmSuisse', 41, true),
  ...makeManyCodes('AMS39_', 'Ramey Insurance Agency', 4, true),
  ...makeManyCodes('AMS42_', 'Advance Insurance Consultants Inc', 1, true),
  ...makeManyCodes('AMS43_', 'Baystate Insurance Group', 2, true),
  ...makeManyCodes('AMS44_', 'Regent Insurance', 4, true),
  ...makeManyCodes('AMS45_', 'i.e. Insurance LLC', 4, true),
  ...makeManyCodes('AMS46_', 'RJ RUPP & ASSOCIATES ', 3, true),
  ...makeManyCodes('AR0006_', 'Armor Insurance Group', 5, true),
  ...makeManyCodes('AR0007_', 'Arthur Blaszczyszyn Insurance Agency Inc. dba One Street Insurance Agency', 4, true),
  ...makeManyCodes('AS0001_', 'American Safety Insurance', 2, true),
  ...makeManyCodes('AS0006_', 'Assured Insurance Consultants, LLC', 4, true),
  ...makeManyCodes('AU0001_', 'Austin Insurance Service Inc.', 3, true),
  ...makeManyCodes('AV0001_', 'Avenue Insurance', 4, true),
  ...makeManyCodes('BA0001_', 'Beasy Insurance Services', 4, true),
  ...makeManyCodes('BA0002_', 'Barton Insurance Group, LLC.', 4, true),
  ...makeManyCodes('BA0003_', 'Barry Winstead', 5, true),
  ...makeManyCodes('BA0004_', 'Baystate Insurance Group', 2, true),
  ...makeManyCodes('BA0005_', 'Bare and Swett Agency Inc', 4, true),
  ...makeManyCodes('BA0006_', 'Baltimore Insurance Network LLC', 1, true),
  ...makeManyCodes('BBC1_', 'Bill Beckman Company', 4, true),
  ...makeManyCodes('BE0001_', 'Better.com', 14, true),
  ...makeManyCodes('BE0003_', 'Beacon Insurance Advisors LLC', 4, true),
  ...makeManyCodes('BE0004_', 'Bennett Insurance Agency', 4, true),
  ...makeManyCodes('BE0005_', 'BetterState Insurance Inc.', 4, true),
  ...makeManyCodes('BE0006_', 'Beacon Point Insurance Group', 4, true),
  ...makeManyCodes('BCHMRK', 'Benchmark', 2, true),
  ...makeManyCodes('BJ0001_', 'B&J Insurance Agency, Inc.', 2, true),
  ...makeManyCodes('BN0001_', 'BNA Insurance Group, LLC', 2, true),
  ...makeManyCodes('BO0001_', 'BOS Insurance Agency', 4, true),
  ...makeManyCodes('BO0003_', 'Boersma Insurance Services', 10, true),
  ...makeManyCodes('BO0004_', 'Bouslog Insurance', 10, true),
  ...makeManyCodes('BU0002_', 'Buford Insurance', 10, true),
  ...makeManyCodes('BU0003_', 'Butz Insurance Agency', 3, true),
  ...makeManyCodes('BR0001_', 'Brightway Insurance, LLC', 100, true),
  ...makeManyCodes('BR0005_', 'Bridgemark Insurance Solutions, Inc. / Key Insurance', 10, true),
  ...makeManyCodes('BR0006_', 'Braly Insurance Group LLC', 8, true),
  ...makeManyCodes('BR0007_', 'Bronco Insurance Group', 2, true),
  ...makeManyCodes('BR0008_', 'Breathe Insurance Solutions, LLC', 13, true),
  ...makeManyCodes('BW0001_', '3000 Insurance Group LLC', 100, true),
  ...makeManyCodes('BY0001_', 'Byers Insurance Group', 5, true),
  ...makeManyCodes('CA0001_', 'Careington Benefit Solutions', 4, true),
  ...makeManyCodes('CA0002_', 'Canopy Insurance Group, LLC', 2, true),
  ...makeManyCodes('CA0004_', 'Caribou Insurance Services, LLC', 6, true),
  ...makeManyCodes('CA0006_', 'Canon Insurance Advisers LLC', 5, true),
  ...makeManyCodes('CA0007_', 'Canyon Lands Insurance 1 Inc', 5, true),
  ...makeManyCodes('CA0008_', 'The Calara Agency', 2, true),
  ...makeManyCodes('CA0009_', 'Ownership Insurance', 7, true),
  ...makeManyCodes('CA0010_', 'Hyde Group Wealth Management & Insurance Solutions', 1, true),
  ...makeManyCodes('CA0011_', 'Seamless Insurance', 1, true),
  ...makeManyCodes('CA0012_', 'Traditions Insurance Group LLC', 1, true),
  ...makeManyCodes('CA0013_', 'Optimal Insurance AZ', 1, true),
  ...makeManyCodes('CA0014_', 'Design Risk', 3, true),
  ...makeManyCodes('CA0015_', 'KCM INSURANCE AGENCY', 4, true),
  ...makeManyCodes('CE0002_', 'Center of Insurance/Hopkinsville', 4, true),
  ...makeManyCodes('CE0003_', 'Central States Insurance Group LLC', 3, true),
  ...makeManyCodes('CH0001_', 'Charles E Deale & Associates Insurance Agency', 4, true),
  ...makeManyCodes('CH0004_', 'Chesapeake Regional Insurance Group LLC.', 8, true),
  ...makeManyCodes('CH0005_', 'Chittick Advisory Group Inc.', 2, true),
  ...makeManyCodes('CH0006_', 'Charles E Osborn Agency Inc.', 4, true),
  ...makeManyCodes('CI0004_', 'CIC Insurance Brokers LLC dba InsureCherokee', 7, true),
  ...makeManyCodes('CL0001_', 'Colling Insurance Services, Inc.', 4, true),
  ...makeManyCodes('CO0009_', 'Coriano Insurance Agency', 4, true),
  ...makeManyCodes('CO0010_', 'Conley Insurance Agency', 10, true),
  ...makeManyCodes('CO0011_', 'Coverage Direct CUSO LLC', 8, true),
  ...makeManyCodes('CU0001_', 'Community Insurance Solutions', 4, true),
  ...makeManyCodes('CU0002_', 'CU Insurance Services of TN, LLC', 8, true),
  ...makeManyCodes('CR0002_', 'Cribb Insurance Group Inc.', 8, true),
  ...makeManyCodes('CA0003_', 'Capitol Insurance', 10, true),
  ...makeManyCodes('CB0001_', 'Cobble Insurance Agency, Inc', 10, true),
  ...makeManyCodes('CJ0001_', 'CJ Insurance Group', 12, true),
  ...makeManyCodes('CK0001_', 'Corkren Insurance LLC', 4, true),
  ...makeManyCodes('CISS', 'Colorado Insurance', 100, true, 'CISS'),
  ...makeManyCodes('CLF0001_', 'Cliff Insurance Agency', 6, true),
  ...makeManyCodes('CLO', 'Clover Insurance Agency', 2, true),
  ...makeManyCodes('CO0006_', 'Commonwealth Insurance Services', 30, true),
  ...makeManyCodes('CO0007_', 'Conour Insurance Inc.', 30, true),
  ...makeManyCodes('CO0008_', 'Coastal Alabama Insurance', 5, true),
  ...makeManyCodes('CP0001_', 'Chip Berry Insurance', 4, true),
  ...makeManyCodes('CR0001_', 'Craig Miller Insurance Inc.', 3, true),
  ...makeManyCodes('CV0002_', 'CoVerica', 28, true),
  ...makeManyCodes('CY0001_', 'Coyle Insurance', 3, true),
  ...makeManyCodes('CM0001_', 'Comma Insurance', 39, true),
  ...makeManyCodes('DA0001_', 'Dorsey and Associates LLC', 5, true),
  ...makeManyCodes('DA0005_', 'Dagley Insurance Agency, LLC', 5, true),
  ...makeManyCodes('DA0007_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0008_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0009_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0010_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0011_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0012_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0013_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0014_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0015_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0016_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0017_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0018_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0019_', 'Dakota Financial Services', 5, true),
  ...makeManyCodes('DA0020_', 'Dakota Financial Services', 10, true),
  ...makeManyCodes('DA0021_', 'Darin Hoover Insurance Inc. dba McGhee Insurance Agency', 50, true),
  ...makeManyCodes('DD0001_', 'DDS Agencies, Inc.', 3, true),
  ...makeManyCodes('DI0002_', 'Diamond Assurance Group', 5, true),
  ...makeManyCodes('DI0003_', 'Ditsler Insurance Agency, LLC.', 7, true),
  ...makeManyCodes('DI0004_', 'Diligent Insurance Group Inc.', 5, true),
  ...makeManyCodes('DI0005_', 'Dillehay Insurance Agency, LLC', 7, true),
  ...makeManyCodes('DI0006_', 'Direct Insurance Services LLC', 9, true),
  ...makeManyCodes('DE0001_', 'Derek Wiley Agency, Inc.', 3, true),
  ...makeManyCodes('DE0003_', 'Design Risk', 3, true),
  ...makeManyCodes('DW0001_', 'Dan M Weier Agency', 3, true),
  ...makeManyCodes('DH0001_', 'Darkhorse Insurance Services, LLC', 1, true),
  ...makeManyCodes('DS0001_', 'Acrisure, LLC dba DSD Insurance', 7, true),
  ...makeManyCodes('EL0002_', 'Ellis Insurance Agency', 10, true),
  ...makeManyCodes('EL0003_', 'ELEMENT INSURANCE GROUP', 1, true),
  ...makeManyCodes('EL0004_', 'Elite Insurance Group, Inc.', 75, true),
  ...makeManyCodes('EQ0002_', 'Community Insurance Agency LLC', 4, true),
  ...makeManyCodes('EQ0004_', 'Winderl/Kaminsky Insurance Agency', 10, true),
  ...makeManyCodes('EQ0005_', 'Omni Insurance Solutions, LLC.', 4, true),
  ...makeManyCodes('EQ0006_', 'Mvest Insurance and Financial Services', 4, true),
  ...makeManyCodes('EQ0007_', 'Lockwood Financial Insurance Services, LLC.', 20, true),
  ...makeManyCodes('EQ0008_', 'Prima Insurance LLC.', 20, true),
  ...makeManyCodes('EQ0011_', 'The Atherton Group, LLC.', 20, true),
  ...makeManyCodes('EQ0014_', 'Fidelity Insruance & Financial Services', 20, true),
  ...makeManyCodes('EQ0013_', 'Robbins Insurance & Financial Services', 20, true),
  ...makeManyCodes('EQ0015_', 'Columbine, LTD', 5, true),
  ...makeManyCodes('EQ0016_', 'The TAK Group, LLC.', 5, true),
  ...makeManyCodes('EQ0017_', 'The Silver & McDonough Company, LLC', 2, true),
  ...makeManyCodes('EQ0018_', 'Synergy Insurance Advisors, LLC', 4, true),
  ...makeManyCodes('EQ0019_', 'SOHA Insurance Brokers, LLC', 5, true),
  ...makeManyCodes('EQ0020_', 'The Ryan Borland Agency, LLC', 5, true),
  ...makeManyCodes('EQ0021_', 'American Mortgage and Financial Services, LLC', 3, true),
  ...makeManyCodes('EQ0022_', 'Zampirri Jr, LLC', 5, true),
  ...makeManyCodes('EQ00010_', 'GFAI, LLC', 20, true),
  ...makeManyCodes('EV0001_', 'Evans Insurance Services', 4, true),
  ...makeManyCodes('FB0001_', 'Family Based Agency LLC', 3, true),
  ...makeManyCodes('FF0001_', 'Family First Insurance Alliance', 3, true),
  ...makeManyCodes('FG0001_', '4G Insurance Brokers', 3, true),
  ...makeManyCodes('FI0001_', 'Faith Insurance Agency', 4, true),
  ...makeManyCodes('FOF0001_', '405 Insurance Advisors, LLC', 5, true),
  ...makeManyCodes('FO0003_', 'Foundation Insurance', 10, true),
  ...makeManyCodes('FO0004_', 'Focus1 Insurance Group, Inc.', 20, true),
  ...makeManyCodes('FR0001_', 'Freedom Insurance Group', 10, true),
  ...makeManyCodes('GA0001_', 'Gary Thacker Insurance', 5, true),
  ...makeManyCodes('GO0001_', 'Goggans Fort Payne Inc DBA Goggins Insurance', 14, true),
  ...makeManyCodes('GO0002_', 'Golden Key Insurance', 2, true),
  ...makeManyCodes('GO0003_', 'Goodhole Nolte Insurance', 10, true),
  ...makeManyCodes('GO0004_', 'Goodman Family Insurance', 1, true),
  ...makeManyCodes('GO0005_', 'GOINS & GOINS INSURANCE AGENCY INC.', 6, true),
  ...makeManyCodes('GR0001_', 'Grove Insurance Company Inc.', 3, true),
  ...makeManyCodes('GR0003_', 'GREGORY D PETERSON LLC', 3, true),
  ...makeManyCodes('GR0004_', 'Grace - Lee Insurance Agency LLC', 1, true),
  ...makeManyCodes('GR0005_', 'Great Plains Insurance Agency, LLC', 2, true),
  ...makeManyCodes('GU0001_', 'Guided Insurance Solutions', 200, true),
  ...makeManyCodes('GU0002_', 'Guaranteed Rate Insurance', 200, true),
  ...makeManyCodes('HA0002_', 'Handzel & Associates Ltd', 12, true),
  ...makeManyCodes('HA0004_', 'Hanger Insurance Group, Inc.', 12, true),
  ...makeManyCodes('HA0005_', 'Hart Insurance Agency', 12, true),
  ...makeManyCodes('HD0001_', 'HD YORK INSURANCE & ASSC', 2, true),
  ...makeManyCodes('HE0004_', 'Heartland Insurance', 50, true),
  ...makeManyCodes('HE0005_', 'Morse Harwell Jiles Insurance', 27, true),
  ...makeManyCodes('HE0006_', 'Hees Insurance Agency LLC', 2, true),
  ...makeManyCodes('HI0004_', 'Hiebert Agency', 2, true),
  ...makeManyCodes('HO0001_', 'HomeFirst Agency, Inc.', 10, true),
  ...makeManyCodes('HO0002_', 'Holly McMillin Insurance Group', 2, true),
  ...makeManyCodes('HO0003_', 'QB and T, LLC DBA House of Insurance', 2, true),
  ...makeManyCodes('HO0004_', 'Homeflow Insurance Services', 2, true),
  ...makeManyCodes('HU0001_', 'Hubler Financial Services II, Inc.', 5, true),
  ...makeManyCodes('HY0001_', 'Hylant Group, Inc.', 20, true),
  ...makeManyCodes('HRZ', 'HRZ', 75, true),
  ...makeManyCodes('HRZ56_', 'Patriot Insurance Group LLC', 2, true),
  ...makeManyCodes('HRZ57_', 'Duckworth Insurance Group, LLC', 1, true),
  ...makeManyCodes('IF1_', 'Integrity 1st, INC DBA Integrity First Insurance', 21, true),
  ...makeManyCodes('IHL0001_', 'IHLC Insurance Agency LLC/dba Univista Insurance', 5, true),
  ...makeManyCodes('IMB0001_', 'IMB, LLC', 35, true),
  ...makeManyCodes('IMSG', 'IMSG Insurance', 11, true),
  ...makeManyCodes('IN0001_', 'Insurance Professionals of Arizona', 50, true),
  ...makeManyCodes('IN0003_', 'Insurance Professional Agency Inc.', 50, true),
  ...makeManyCodes('IN0007_', 'Insight Risk Management LLC', 50, true),
  ...makeManyCodes('IN00010_', 'InsureIt, Inc', 3, true),
  ...makeManyCodes('IN00011_', 'Jordan Barnett Agency', 50, true),
  ...makeManyCodes('IN00014_', 'Indium', 50, true),
  ...makeManyCodes('IN0015_', 'Insurtech Groups LLC.', 50, true),
  ...makeManyCodes('IN0016_', 'Insurance Geek', 50, true),
  ...makeManyCodes('IN0018_', 'Alabama Insurance Agency', 50, true),
  ...makeManyCodes('IN0019_', 'Insurtech Group LLC.', 50, true),
  ...makeManyCodes('IN0021_', 'INSURANCE EXPRESS, LLC', 50, true),
  ...makeManyCodes('IN0022_', 'Insurance Services of Southern Oregon LLC', 50, true),
  ...makeManyCodes('IN0023_', 'Integrity Insurance Group, Inc.', 50, true),
  ...makeManyCodes('IN0024_', 'Neverman Insurane Agency', 50, true),
  ...makeManyCodes('IN0025_', 'Virtuoso Insurance Advisors', 50, true),
  ...makeManyCodes('IN0026_', 'Insurance Pros Inc DBA Maine', 50, true),
  ...makeManyCodes('IN0029_', 'Insurance Unlimited of Bozeman', 4, true),
  ...makeManyCodes('IN0030_', 'Insurance Services Group, LLC', 1, true),
  ...makeManyCodes('IN0031_', 'Insurance Gurus LLC', 1, true),
  ...makeManyCodes('IN0032_', 'Outcalt Kerns Insurance LLC', 3, true),
  ...makeManyCodes('IN0033_', 'Strong Agency LLC', 2, true),
  ...makeManyCodes('IN0034_', 'Veal Insurance Agency, Inc', 6, true),
  ...makeManyCodes('IN0035_', 'Exel Underwritters', 1, true),
  ...makeManyCodes('IN0036_', 'Choices Independent Insurance Agency', 1, true),
  ...makeManyCodes('IN0037_', 'We Care Insurance', 1, true),
  ...makeManyCodes('IN0038_', 'Seibt Insurance Agency', 1, true),
  ...makeManyCodes('IN0039_', 'Duffy Insurance Partners LLC', 5, true),
  ...makeManyCodes('IN0040_', 'Integrity Insurance Brokers', 1, true),
  ...makeManyCodes('IN0041_', 'Insure It All', 13, true),
  ...makeManyCodes('IN0042_', 'AgriPeril Risk managment', 1, true),
  ...makeManyCodes('IN0043_', 'Dunn Insurance Agency', 1, true),
  ...makeManyCodes('IN0044_', 'Hearthstone Insurance Group LLC', 4, true),
  ...makeManyCodes('IN0045_', 'Insurance Pros', 1, true),
  ...makeManyCodes('IN0046_', 'Insurance Express Agency, LLC', 3, true),
  ...makeManyCodes('IN0047_', 'Christopher Kamper Insurance Agency Inc', 1, true),
  ...makeManyCodes('IN0048_', 'InsureOne Insurance Services America LLC', 13, true),
  ...makeManyCodes('INT', 'Integra Insurance Services', 32, true),
  ...makeManyCodes('IR0001_', 'Iron City Insurance Brokers, Inc.', 4, true),
  ...makeManyCodes('IS0001_', 'Insurance Shoppe, LLC', 4, true),
  ...makeManyCodes('IS0003_', 'ISU Insurance Planning Services', 7, true),
  ...makeManyCodes('ISG1_', 'Insurance Solutions Group', 8, true),
  ...makeManyCodes('ISG', 'Insurance Solutions Group', 10, true),
  ...makeManyCodes('IP0001_', 'Insurance Plus LLC', 4, true),
  ...makeManyCodes('JA0002_', 'Jackson Insurance Group', 6, true),
  ...makeManyCodes('JCI0001_', 'J. Charles Insurance', 10, true),
  ...makeManyCodes('JC0001_', 'John R Carpenter Insurance Agency Inc', 20, true),
  ...makeManyCodes('JC0002_', 'J CONRAD COFFEY INSURANCE AGENCY, LLC', 20, true),
  ...makeManyCodes('JEN0001_', 'Jennings Insurance Solutions', 4, true),
  ...makeManyCodes('JE0003_', 'Jerry Financial and Insurance Services', 200, true),
  ...makeManyCodes('JL0001_', 'John Letson Insurance Agency Inc.', 2, true),
  ...makeManyCodes('JL0002_', 'JLB Consulting Inc. DBA Advantage Insurance', 20, true),
  ...makeManyCodes('JO0004_', 'Jones Insurance Group LLC', 4, true),
  ...makeManyCodes('JO0005_', 'JOSE VARGAS INSURANCE AGENCY LLC', 4, true),
  ...makeManyCodes('JO0006_', 'Joey Proffitt Agency,Ind', 2, true),
  ...makeManyCodes('JO0007_', 'Joel Rieth Ins Agency Inc', 5, true),
  ...makeManyCodes('JS0001_', 'Justice Couch Insurance', 4, true),
  ...makeManyCodes('JU0002_', 'Jupiter Auto Insurance', 2, true),
  ...makeManyCodes('JW0001_', 'J Wood Insurance', 3, true),
  ...makeManyCodes('KI0001_', 'KIIA Insurance', 1, true),
  ...makeManyCodes('KI0002_', 'Knisely Insurance Agency Inc', 1, true),
  ...makeManyCodes('KO0002_', 'Kouri Insurance Agency', 5, true),
  ...makeManyCodes('KOVKG', 'Kover King', 1, true),
  ...makeManyCodes('KP0001_', 'Kris Posey Agency', 3, true),
  ...makeManyCodes('LA0002_', 'Laramie Investment Company', 8, true),
  ...makeManyCodes('LE0002_', 'Legacy Insurance Group', 8, true),
  ...makeManyCodes('LE0001_', 'Legacy Insurance Enterprises', 3, true),
  ...makeManyCodes('LE0004_', 'Leap Insurance', 4, true),
  ...makeManyCodes('LD0001_', 'L & D Insurance Services, LLC.', 10, true),
  ...makeManyCodes('LI0001_', 'Lighthouse Insurance Agency', 2, true),
  ...makeManyCodes('LO0001_', 'Lord Insurance Company, Inc', 6, true),
  ...makeManyCodes('LT0002_', 'LTN Insurance Services LLC', 5, true),
  ...makeManyCodes('LU0004_', 'Martin McNew Insurance', 4, true),
  ...makeManyCodes('LU0005_', 'AR Insurance Express Center LLc', 3, true),
  ...makeManyCodes('LW0001_', 'Loftis & Wetzel Corporation', 5, true),
  ...makeManyCodes('LW0002_', 'Loftis & Wetzel Corporation', 5, true),
  ...makeManyCodes('LW0003_', 'Loftis & Wetzel Corporation', 5, true),
  ...makeManyCodes('LW0004_', 'Loftis & Wetzel Corporation', 5, true),
  ...makeManyCodes('MA0001_', 'Midwest Agency', 10, true),
  ...makeManyCodes('MA0003_', 'Reisert Insurance Inc.', 50, true),
  ...makeManyCodes('MA0006_', 'May Insurance Financial Services', 5, true),
  ...makeManyCodes('MA0007_', 'Maple Insurance Group', 7, true),
  ...makeManyCodes('MA0008_', 'Marin Miller & Associates Insurance', 6, true),
  ...makeManyCodes('MA0009_', 'Reisert Insurance Inc.', 4, true),
  ...makeManyCodes('MA0010_', 'Reisert & Associates, Inc', 3, true),
  ...makeManyCodes('MA0011_', 'Western KY Insurance Agency of Murray Inc. DBA Suiter Insurance Group', 4, true),
  ...makeManyCodes('MA0012_', 'Luckett & Luckett Ins Inc', 2, true),
  ...makeManyCodes('MA0013_', 'Bradshaw & Weil, Inc.', 2, true),
  ...makeManyCodes('MA0016_', 'Johnson Lawrence and Walker Insurance Agency', 9, true),
  ...makeManyCodes('MA0017_', 'Madison Insurance Group', 11, true),
  ...makeManyCodes('MA0018_', 'Chaplin Insurance Agency, Inc. d/b/a Whitehouse & Pulliam Insurance Agency', 4, true),
  ...makeManyCodes('MA0020_', 'Marble City Insurance LLC', 1, true),
  ...makeManyCodes('MA0021_', 'Mayne Insurance Agency LLC', 2, true),
  ...makeManyCodes('MC0002_', 'McMillan Insurance Agency', 10, true),
  ...makeManyCodes('MC0004_', 'McDonald Insurance Group of Colorado LLC', 10, true),
  ...makeManyCodes('MC0005_', 'McClain Matthews Insurance Inc', 2, true),
  ...makeManyCodes('ME0003_', 'Meloniece Gaskin Insurance Agency LLC', 1, true),
  ...makeManyCodes('ME0004_', 'Merit Insurance Advisors', 2, true),
  ...makeManyCodes('ME0005_', 'Metro Insurance', 4, true),
  ...makeManyCodes('MI0008_', 'MidArk Insurance Group Inc.', 20, true),
  ...makeManyCodes('MI0009_', 'Miller Insurance Team, llc', 2, true),
  ...makeManyCodes('MI0010_', 'Millennium Brokers Group', 4, true),
  ...makeManyCodes('MM0001_', 'M&M Financial Corporation', 20, true),
  ...makeManyCodes('MO0004_', 'Morin Insurance Agency', 50, true),
  ...makeManyCodes('MO0005_', 'Montgomery and Associates', 1, true),
  ...makeManyCodes('MU0001_', 'Muir Insurance Group', 5, true),
  ...makeManyCodes('MU0002_', 'Mutual Insurance Partners LLC', 5, true),
  ...makeManyCodes('MU0003_', 'MURPHY INSURANCE AGENCY', 1, true),
  ...makeManyCodes('MW0001_', 'MWI Insurance Brokers', 10, true),
  ...makeManyCodes('MY0001_', 'My Insurance Agent, Inc.', 8, true),
  ...makeManyCodes('MY0003_', 'MYLO, LLC', 100, true),
  ...makeManyCodes('MY0004_', 'MyFlag Insurance Agency LLC', 3, true),
  ...makeManyCodes('NA0001_', 'Navigate Insurance Advisors', 10, true),
  ...makeManyCodes('NE0002_', 'New Eagle Insurance', 10, true),
  ...makeManyCodes('NE0003_', 'Next Level Insurance Solutions', 10, true),
  ...makeManyCodes('OAC', 'Ohio Auto Club', 5, true),
  ...makeManyCodes('OH0001_', 'Ohio Insurance Alliance Group', 30, true),
  ...makeManyCodes('OK0002_', 'Oklahoma Insurance Professionals, LLC', 2, true),
  ...makeManyCodes('ON0002_', 'Onstot and Neal Insurance LLC', 10, true),
  ...makeManyCodes('OP0001_', 'Optimal Insurance AZ', 30, true),
  ...makeManyCodes('OW0001_', 'Ownership Insurance', 10, true),
  ...makeManyCodes('OZ0001_', 'Ozark Hills Insurance', 10, true),
  ...makeManyCodes('PA0002_', 'Pacheco & Associates Inc.', 3, true),
  ...makeManyCodes('PA0005_', 'Pacific Ridge Insurance', 6, true),
  ...makeManyCodes('PA0006_', 'Paul Moss Insurance Agency dba epiq Insurance Agency', 50, true),
  ...makeManyCodes('PA0007_', 'Patriot Insurance Group LLC', 2, true),
  ...makeManyCodes('PA0008_', 'PATRICK INSURANCE GROUP INC.', 1, true),
  ...makeManyCodes('PC0001_', 'P&C Insurance Services', 6, true),
  ...makeManyCodes('PC0002_', 'Pacific Crest', 1, true),
  ...makeManyCodes('PC0003_', 'Elson Insurance Agency', 1, true),
  ...makeManyCodes('PC0004_', 'Hankins Insurance Group', 1, true),
  ...makeManyCodes('PC0005_', 'Safeguard Insuranced LLC', 1, true),
  ...makeManyCodes('PC0006_', 'Pacific Crest Services', 1, true),
  ...makeManyCodes('PC0007_', 'Pacheco Independent Insurance', 1, true),
  ...makeManyCodes('PC0008_', 'Knigge Agency', 1, true),
  ...makeManyCodes('PC0009_', 'Pacific Crest Services dba Insurance Solutions', 2, true),
  ...makeManyCodes('PC0010_', 'Sumrall Agency Inc', 1, true),
  ...makeManyCodes('PC0011_', 'Kassa Hartley Insurance', 1, true),
  ...makeManyCodes('PC0012_', 'Gregory C Clark', 1, true),
  ...makeManyCodes('PC0013_', 'Hart Insurance Services LLC', 1, true),
  ...makeManyCodes('PC0014_', 'Fredette Insurance Agency', 1, true),
  ...makeManyCodes('PC0015_', 'Tom Schrader', 1, true),
  ...makeManyCodes('PC0016_', 'Solstice Insurance Inc', 1, true),
  ...makeManyCodes('PC0017_', 'Arriaga Insurance Advisors', 1, true),
  ...makeManyCodes('PE0001_', 'People Ins. Inc.', 3, true),
  ...makeManyCodes('PF0001_', 'PFS Insurance Group, LLC Sterling', 5, true),
  ...makeManyCodes('PF0002_', 'PFS Insurance Group, LLC Yuma', 3, true),
  ...makeManyCodes('PH0001_', 'Philleo Agency Insurance', 2, true),
  ...makeManyCodes('PH0002_', 'Smith Mountain Lake Insurance Agency Inc', 4, true),
  ...makeManyCodes('PI0003_', 'Guard Home and Auto LLC', 2, true),
  ...makeManyCodes('PI0004_', 'Pisk Insurance LLC', 2, true),
  ...makeManyCodes('PI0005_', 'Gateway Home and Auto Insurance', 3, true),
  ...makeManyCodes('PI0006_', 'Utah Home and Auto LLC', 2, true),
  ...makeManyCodes('PL0001_', 'Planners Insurane Group of America LLC', 6, true),
  ...makeManyCodes('PL0002_', 'Platinum Insurance Group', 3, true),
  ...makeManyCodes('PO0002_', 'Potter Insurance Agency Inc.', 3, true),
  ...makeManyCodes('PO0003_', 'Postema Insurance and Investments', 4, true),
  ...makeManyCodes('PP', 'Perfect Policy', 24, true),
  ...makeManyCodes('PR0001_', 'Provision Insurance Group, LLC.', 45, true, '', ['MI']),
  ...makeManyCodes('PR0003_', 'Premier Group Insurance, LLC', 6, true),
  ...makeManyCodes('PR0010_', 'Atlas Insurance Services LLC', 2, true),
  ...makeManyCodes('PR0040_', 'Cheyenne Mountain Insurance Solutions', 6, true),
  ...makeManyCodes('PR0041_', 'Hometown Insurance Group', 6, true),
  ...makeManyCodes('PR0042_', 'Nicholls Insurance Agency', 6, true),
  ...makeManyCodes('PR0043_', 'HomeOwners Advantage Insurance', 6, true),
  ...makeManyCodes('PR0058_', 'Professional Insurance Management Agency Inc.', 10, true),
  ...makeManyCodes('PR0060_', 'JSH Insurance Solutions', 2, true),
  ...makeManyCodes('PR0061_', 'Premier Alliance P&C, Inc', 4, true),
  ...makeManyCodes('PR0062_', 'Jacks Insurance', 1, true),
  ...makeManyCodes('PR0063_', 'Big Bear Insurance', 1, true),
  ...makeManyCodes('PR0064_', 'The Willow Oaks Group LLC', 1, true),
  ...makeManyCodes('PR0065_', 'JWR Insurance Group Inc', 3, true),
  ...makeManyCodes('PR0066_', 'Sentinel Advisors', 1, true),
  ...makeManyCodes('PR0067_', 'Deanna Vega Agency', 1, true),
  ...makeManyCodes('PR0068_', 'Jay A. Hines Insurance Agency', 1, true),
  ...makeManyCodes('PR0069_', 'Graham Insurance Agency', 1, true),
  ...makeManyCodes('PR0070_', 'Premier Insurance Agency', 8, true),
  ...makeManyCodes('PR0071_', 'CASTANEDA INSURANCE AGENCY', 1, true),
  ...makeManyCodes('PR0072_', 'Premier Insurance', 2, true),
  ...makeManyCodes('PR0073_', 'Premier Insurance Group LLC', 3, true),
  ...makeManyCodes('PR0074_', 'Prizm Insurance, Inc.', 1, true),
  ...makeManyCodes('PR0075_', 'Dynasty Insurance Services LLC', 1, true),
  ...makeManyCodes('PR0076_', 'Pramuan Insurance', 1, true),
  ...makeManyCodes('PR0077_', 'Rise Insurance Ohio', 2, true),
  ...makeManyCodes('PR0078_', 'MVS Insurance and Investments Team', 1, true),
  ...makeManyCodes('PR0079_', 'Haws Insurance Services', 1, true),
  ...makeManyCodes('PR0080_', 'Professional Insurance', 1, true),
  ...makeManyCodes('PR0081_', 'Fairway Insurance Group LLC', 1, true),
  ...makeManyCodes('PR0082_', 'El Cochinito Azul of NM', 1, true),
  ...makeManyCodes('QA', 'Quantum Assurance', 60, true),
  ...makeManyCodes('QU0001_', 'QuickInsured', 20, true),
  ...makeManyCodes('RB0001_', 'RBI BROKERAGE', 3, true),
  ...makeManyCodes('RC0001_', 'Rich & Cartmill, Inc', 40, true),
  ...makeManyCodes('RC0002_', 'Rich & Cartmill, Inc', 40, true),
  ...makeManyCodes('RCI0001_', 'Rocket City Insurance Group', 5, true),
  ...makeManyCodes('RE0002_', 'RBI Services (Relationship Business and Insurance Services LLC.)', 4, true),
  ...makeManyCodes('RE0004_', 'Redwood Agency Group LLC', 10, true),
  ...makeManyCodes('RE0005_', 'Redstone Insurance Services', 9, true),
  ...makeManyCodes('RI0001_', 'Riverton Insurance Agency', 4, true),
  ...makeManyCodes('RI0002_', 'Rick Allen Insurance Agency', 4, true),
  ...makeManyCodes('RI0003_', 'RICK GALSTER III INSURANCE AGENCY INC', 20, true),
  ...makeManyCodes('RI0004_', 'Risk Management Partners', 4, true),
  ...makeManyCodes('RI0005_', 'Riverside Farmers', 1, true),
  ...makeManyCodes('RJ0001_', 'RJ Bailey Insurance Center', 2, true),
  ...makeManyCodes('RO0002_', 'Rolland Insurance & Financial Services', 4, true),
  ...makeManyCodes('RO0003_', 'Roseberry Insurance Agency', 4, true),
  ...makeManyCodes('RO0004_', 'Robert Traylor Insurance', 10, true),
  ...makeManyCodes('RO0007_', 'Beaver State Insurance Agency, LLC', 10, true),
  ...makeManyCodes('RP0001_', 'RPM Agency', 3, true),
  ...makeManyCodes('RGTLP', 'Reliance Group Texas, L.P.', 5, true),
  ...makeManyCodes('RHK', 'Randy House and Associates', 3, true),
  ...makeManyCodes('RO0001_', 'ROberts Insurance LLC.', 4, true),
  ...makeManyCodes('SE0001_', 'Select Insurance Markets: One80', 44, true),
  ...makeManyCodes('SE0006_', 'Seamless Insurance', 44, true),
  ...makeManyCodes('SF0001_', '643 Insurance, LLC', 2, true),
  ...makeManyCodes('SGIA', 'Southern Group Insurance Agency', 1, true),
  ...makeManyCodes('SK0001_', 'SteelKey Insurance', 14, true),
  ...makeManyCodes('SK0002_', 'Skala Insurance Agency LLC', 14, true),
  ...makeManyCodes('SK0003_', 'Skogman Insurance', 14, true),
  ...makeManyCodes('SI0003_', 'Siskiyou Insurance Marketplace', 5, true),
  ...makeManyCodes('SI0006_', 'Siskiyou Insurance Marketplace', 5, true),
  ...makeManyCodes('SI0007_', 'Simple Insurance, LLC', 3, true),
  ...makeManyCodes('SG0001_', 'Safeguard Insurance', 4, true),
  ...makeManyCodes('SG0002_', 'SGA Financial Inc', 1, true),
  ...makeManyCodes('SH0003_', 'Shannon Jennings Insurance, LLC', 4, true),
  ...makeManyCodes('SL0001_', 'Slyman Insurance Group', 5, true),
  ...makeManyCodes('SM0002_', 'Smith & Company Insurance, Inc.', 4, true),
  ...makeManyCodes('SMAL', 'Smart Choice (AL)', 100, true),
  ...makeManyCodes('SMAR', 'Smart Choice (AR)', 100, true),
  ...makeManyCodes('SMAZ', 'Smart Choice (AZ)', 27, true),
  ...makeManyCodes('SMCH', 'Smart Choice', 1, true),
  ...makeManyCodes('SMCO', 'Smart Choice (CO)', 16, true),
  ...makeManyCodes('SMGA', 'Smart Choice (GA)', 100, true),
  ...makeManyCodes('SMID', 'Smart Choice (ID)', 60, true),
  ...makeManyCodes('SMIN', 'Smart Choice (IN)', 60, true),
  ...makeManyCodes('SMIL', 'Smart Choice (IL)', 60, true),
  ...makeManyCodes('SMKS', 'Smart Choice (KS)', 30, true),
  ...makeManyCodes('SMKY', 'Smart Choice (KY)', 60, true),
  ...makeManyCodes('SMID', 'Smart Choice (ID)', 40, true),
  ...makeManyCodes('SMMD', 'Smart Choice (MD)', 40, true),
  ...makeManyCodes('SMMI', 'Smart Choice (MI)', 2, true, '', ['MI']),
  ...makeManyCodes('SMMO', 'Smart Choice (MO)', 30, true),
  ...makeManyCodes('SMNE', 'Smart Choice (NE)', 30, true),
  ...makeManyCodes('SMNM', 'Smart Choice (NM)', 30, true),
  ...makeManyCodes('SMOH', 'Smart Choice (OH)', 200, true),
  ...makeManyCodes('SMOH102_', 'Gautam Insurance Agency LLC', 4, true),
  ...makeManyCodes('SMOK', 'Smart Choice (OK)', 5, true),
  ...makeManyCodes('SMOR', 'Smart Choice (OR)', 100, true),
  ...makeManyCodes('SMPA', 'Smart Choice (PA)', 60, true),
  ...makeManyCodes('SMTN', 'Smart Choice (TN)', 40, true),
  ...makeManyCodes('SMTX', 'Smart Choice (TX)', 90, true),
  ...makeManyCodes('SMVA', 'Smart Choice (VA)', 5, true),
  ...makeManyCodes('SMWI', 'Smart Choice (WI)', 40, true),
  ...makeManyCodes('SMWV', 'Smart Choice (WV)', 30, true),
  ...makeManyCodes('SO0002_', 'Southwest Insurance Center', 25, true),
  ...makeManyCodes('SO0003_', 'Sound Ventures Inc', 25, true),
  ...makeManyCodes('SO0004_', 'SORGIUS INSURANCE AGENCY', 1, true),
  ...makeManyCodes('SO0005_', 'SouthWest Professional Insurance Consultants, inc.', 2, true),
  ...makeManyCodes('SO0006_', 'SOUTHERN OAKS INSURANCE GROUP', 2, true),
  ...makeManyCodes('SN0001_', 'Sandstone Insurance Group', 2, true),
  ...makeManyCodes('SP0003_', 'SPRING GROVE INSURANCE', 1, true),
  ...makeManyCodes('SS0001_', 'S&S Insurance Group', 3, true),
  ...makeManyCodes('ST0001_', 'Strata Insurance Group, LLC', 10, true),
  ...makeManyCodes('ST0002_', 'Stanley Insurance Group', 7, true),
  ...makeManyCodes('ST0004_', 'Sterling Group Insurance, LLC', 2, true),
  ...makeManyCodes('SU0001_', 'Summit Rigde Insurance', 7, true),
  ...makeManyCodes('SU0002_', 'Sunset Valley Insurance Agency', 2, true),
  ...makeManyCodes('SU0004_', 'Susan Shollenberger Insurance Agency LLC', 2, true),
  ...makeManyCodes('TA0001_', 'Taylor & Assoc Ins, LLC dba King & Associates Insurance', 8, true),
  ...makeManyCodes('TAC0001_', 'The Agency Collective', 100, true),
  ...makeManyCodes('TAC0002_', 'THEAC', 100, true),
  ...makeManyCodes('TAC0008_', 'Sylvia A Garrett Associates', 4, true),
  ...makeManyCodes('TAC00011_', 'Dan W. Weier', 3, true),
  ...makeManyCodes('TAC0013_', 'Stanley Insurance Group', 7, true),
  ...makeManyCodes('TAC0015_', 'Muir Insurance Group, Inc.', 10, true),
  ...makeManyCodes('TAC0017_', 'The Fox Insurance Agencies, LLC', 10, true),
  ...makeManyCodes('TAC0018_', 'Potter Insurance Agency Inc.', 10, true),
  ...makeManyCodes('TAC0020_', 'Andrew Schoch Agency, LLC.', 10, true),
  ...makeManyCodes('TAC0021_', 'Slyman Insurance Group', 10, true),
  ...makeManyCodes('TAC0022_', 'Endgame Insurance', 10, true),
  ...makeManyCodes('TAC0023_', 'Erwin Agency LLC', 10, true),
  ...makeManyCodes('TAC0024_', 'The Orrino Agency, Inc.', 3, true),
  ...makeManyCodes('TAC0025_', 'First Option Insurance Brokerage, LLC.', 10, true),
  ...makeManyCodes('TAC0026_', 'Providence Insurance Advisors LLC', 7, true),
  ...makeManyCodes('TAC0027_', 'MIKE LAMBERT AGENCY', 2, true),
  ...makeManyCodes('TAC0028_', 'Tony Powers Insurance', 2, true),
  ...makeManyCodes('TAC0029_', 'Ditsler Insurance Agency, LLC', 8, true),
  ...makeManyCodes('TAC0030_', 'Wenhold Insurance Agency LLC', 7, true),
  ...makeManyCodes('TAC0031_', 'Schenck Insurance', 8, true),
  ...makeManyCodes('TAC0033_', 'Toney Dvis Insurance, Inc. DBA Halo Insurance Agency', 8, true),
  ...makeManyCodes('TAC0034_', 'Gibson Insurance and Financial Services', 8, true),
  ...makeManyCodes('TAC0036_', 'Whitehill Insurance, LLC.', 8, true),
  ...makeManyCodes('TAC0037_', 'Patrick Daughtrey Inc. dba Appletree Insurance LLC', 8, true),
  ...makeManyCodes('TAC0038_', 'The Golden Rooster LLC.', 8, true),
  ...makeManyCodes('TAC0041_', 'People First Insurance Family', 1, true),
  ...makeManyCodes('TAC0042_', 'American Insurance & Financial Services LLC', 1, true),
  ...makeManyCodes('TAC0043_', 'Insurance Pros Inc DBA Maine Coast Insurance Agency', 4, true),
  ...makeManyCodes('TAC0044_', 'Thai Chang Agency Inc', 10, true),
  ...makeManyCodes('TAC0045_', 'BARRY WINSTEAD', 5, true),
  ...makeManyCodes('TAC0046_', 'Baribault Insurance Agency', 2, true),
  ...makeManyCodes('TAC0047_', 'Your Choice Insurance', 4, true),
  ...makeManyCodes('TAC0048_', 'Tipp Insurance Agency, LLC', 1, true),
  ...makeManyCodes('TAC0049_', 'Scott Scales Insurance Agency INC DBA S SQAURED INSURANCE AGENCY', 4, true),
  ...makeManyCodes('TAC0050_', 'Premier Insurance Group LLC', 3, true),
  ...makeManyCodes('TAC0051_', 'The Loeber Agency', 1, true),
  ...makeManyCodes('TAC0052_', 'Toro Insurance', 1, true),
  ...makeManyCodes('TAC0053_', 'SOUTHERN OAKS INSURANCE GROUP', 2, true),
  ...makeManyCodes('TAC0054_', 'Frank Insurance Management', 3, true),
  ...makeManyCodes('TAC0055_', 'Arc Insurance', 1, true),
  ...makeManyCodes('TAC0056_', 'Advantage Insurance Agency, Inc.', 2, true),
  ...makeManyCodes('TAG0001_', 'The Affinities Group, LLC', 20, true),
  ...makeManyCodes('TE0001_', 'Tedford & Associates, LLC', 10, true),
  ...makeManyCodes('TI0001_', 'The Insurance Alliance', 10, true),
  ...makeManyCodes('TI0003_', 'Titan Insurance Services', 2, true),
  ...makeManyCodes('TIP0001_', 'The Insurance Professionals LLC', 2, true),
  ...makeManyCodes('TIS0001_', 'The Insurance Shop, LLC', 5, true),
  ...makeManyCodes('TIM0001_', 'The Insurance Man dba End Insurance Agency', 7, true),
  ...makeManyCodes('TH0002_', 'The Orrino Agency, Inc.', 3, true),
  ...makeManyCodes('TH0003_', 'The Fox Insurance Agencies LLC.', 6, true),
  ...makeManyCodes('TH0004_', 'The Poland Agency', 6, true),
  ...makeManyCodes('TH0006_', 'The Assurity Group', 4, true),
  ...makeManyCodes('TH0008_', 'The Weigman Group Inc.', 10, true),
  ...makeManyCodes('TH0010_', 'The Shell Agency', 2, true),
  ...makeManyCodes('TH0011_', 'The Leavitt Agency Inc', 2, true),
  ...makeManyCodes('TH0012_', 'The Loeber Agency', 1, true),
  ...makeManyCodes('TH0013_', 'The Star Agency', 1, true),
  ...makeManyCodes('TH0014_', 'THE PICK INSURANCE', 2, true),
  ...makeManyCodes('TH0015_', 'The Peak Agency', 3, true),
  ...makeManyCodes('TH0016_', 'The Leslie Richardson Agency LLC', 1, true),
  ...makeManyCodes('TK0001_', '2K Financial Services LLC DBA The Kennedy Agency', 5, true),
  ...makeManyCodes('TO0001_', 'Troy Powers Insurance', 6, true),
  ...makeManyCodes('TO0002_', 'Tony Powers Insurance', 6, true),
  ...makeManyCodes('TO0003_', 'Town & Country Insurance LLC.', 6, true),
  ...makeManyCodes('TO0004_', 'Tolliver Insurance Agency Inc.', 6, true),
  ...makeManyCodes('TR0004_', 'Trust Hall Insurance Services', 8, true),
  ...makeManyCodes('TR0005_', 'Triangle Insurance Group', 4, true),
  ...makeManyCodes('TT0002_', '321Insurance, LLC', 1, true),
  ...makeManyCodes('TWFG', 'TWFG Insurance Services, LLC', 300, true),
  ...makeManyCodes('TOP', 'TOP Group', 11, true),
  ...makeManyCodes('UN0002_', 'UNITED INSURANCE GROUP LLC', 1, true),
  ...makeManyCodes('UP0001_', 'Uptown Insurance, LLC.', 20, true),
  ...makeManyCodes('UR0001_', 'UR Choice Holdings, LLC', 1, true),
  ...makeManyCodes('VIF', 'Voldico Franchise', 50, true),
  ...makeManyCodes('VIN', 'Voldico Network', 50, true),
  ...makeManyCodes('WA0003_', 'Waverly Insurance Group, LLC', 10, true),
  ...makeManyCodes('WA0004_', 'Walker Insurance Associates', 3, true),
  ...makeManyCodes('WA0005_', 'Wayfinder Insurance', 1, true),
  ...makeManyCodes('WA0006_', 'Warrior Insurance and Services Group LLC', 8, true),
  ...makeManyCodes('WAY0001_', 'Way.com Insurance Agency', 250, true),
  ...makeManyCodes('WI0001_', 'Wise Men Insurance Services', 2, true),
  ...makeManyCodes('WI0006_', 'Willoughby Insurance Services', 1, true),
  ...makeManyCodes('WE0002_', 'Weaver Insurance Group, LLC', 20, true),
  ...makeManyCodes('WE0005_', 'Weston Bradbury Insurance Services LLC', 5, true),
  ...makeManyCodes('WE0006_', 'Weatherford Insurance', 5, true),
  ...makeManyCodes('WH0002_', 'Whittaker Chillton Agency', 4, true),
  ...makeManyCodes('WO0001_', 'Woop Insurance Agency', 5, true),
  ...makeManyCodes('WO0002_', 'Woodstock Car Insurance, LLC', 1, true),
  ...makeManyCodes('YO0001_', 'Insurance & Financial', 20, true),
  ...makeManyCodes('YO0002_', 'Your Insurance Specialists', 3, true)
};
