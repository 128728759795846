import {
  policyType as PolicyType,
  paymentMethod as PaymentMethod,
  windHailDeductibleCanVaryByCounty,
  lapsedLicenseMonthsStates
} from '@ourbranch/lookups';

export const hasPaymentMethod = ({
  paymentMethods,
  paymentMethod,
  policyType,
  noBindHome = false,
  noBindAuto = false
}) => {
  const { homeownersPaymentMethod, autoPaymentMethod, rentersPaymentMethod } = paymentMethods;
  // probably a cleverer way to do this but this works
  return (
    (policyType === PolicyType.Auto && autoPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.ARBundle && autoPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.Renters && rentersPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.Home && homeownersPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.HomeBundle && homeownersPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.AutoBundle && autoPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.HABundle && !noBindHome && !noBindAuto && homeownersPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.HABundle && !noBindHome && !noBindAuto && autoPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.HABundle && noBindHome && autoPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.HABundle && noBindAuto && homeownersPaymentMethod === paymentMethod)
  );
};

export const positionToError = (e, formErrors) => {
  const positionConfig = [
    { condition: (fe) => fe.homeEffectiveDate, scrollTo: '[name=homeEffectiveDate]' },
    {
      condition: (fe) => fe.mortgageDetails && fe.mortgageDetails.mortgageHolderName,
      scrollTo: '[id=mortgageSection]'
    },
    { condition: (fe) => fe.attestationsHomeAccepted, scrollTo: '[name=attestationsHomeAccepted]' },
    { condition: (fe) => fe.autoEffectiveDate, scrollTo: '[name=autoEffectiveDate]' },
    { condition: (fe) => fe.attestationsAutoAccepted, scrollTo: '[name=attestationsAutoAccepted]' },
    { condition: (fe) => fe.currentHomeownersCarrier, scrollTo: '[name=currentHomeownersCarrier]' },
    { condition: (fe) => fe.currentAutoCarrier, scrollTo: '[name=currentAutoCarrier]' }
  ];

  for (let i = 0; i < positionConfig.length; i += 1) {
    if (positionConfig[i].condition(formErrors) && document.querySelector(positionConfig[i].scrollTo)) {
      document.querySelector(positionConfig[i].scrollTo).scrollIntoView({ behavior: 'smooth', block: 'center' });
      throw formErrors;
    }
  }

  if (document.querySelector('[name=firstName]')) {
    document.querySelector('[name=firstName]').scrollIntoView({ behavior: 'smooth' });
  }
};

export const getPaymentMethods = ({
  paymentMethods,
  policyType,
  noBindHome = false,
  noBindAuto = false,
  hasAbodeProPlan = false
}) => {
  let hasCreditCard;
  let hasACH;
  let hasEscrow;
  if (paymentMethods) {
    hasCreditCard =
      hasPaymentMethod({
        paymentMethods,
        paymentMethod: PaymentMethod.CreditCard,
        policyType,
        noBindHome,
        noBindAuto
      }) || hasAbodeProPlan;
    hasACH = hasPaymentMethod({
      paymentMethods,
      paymentMethod: PaymentMethod.ACH,
      policyType,
      noBindHome,
      noBindAuto
    });
    hasEscrow = hasPaymentMethod({
      paymentMethods,
      paymentMethod: PaymentMethod.Escrow,
      policyType,
      noBindHome,
      noBindAuto
    });
  }

  return { hasCreditCard, hasACH, hasEscrow };
};

export const getPreSaleChecklistConditions = (quote, policyType, values) => {
  const { correctedAddress, drivers, homeCoverage, home, includeEarthquake, includeFlood, global } = quote;
  const primaryDriver = drivers?.find((driver) => driver.isPrimary);

  const { state } = correctedAddress;
  const { interestedInSeparateWindHail, windHailExclusion } = homeCoverage;
  const { breakupWithAuto, breakupWithHomeowners } = values;
  const primaryIsMarried = primaryDriver?.maritalStatus === 'M' || primaryDriver?.maritalStatus === 'W';
  const stressFreeSwitch = breakupWithAuto || breakupWithHomeowners;
  const noWindHailInformed =
    windHailExclusion &&
    ((state === 'AL' && !interestedInSeparateWindHail) ||
      (windHailDeductibleCanVaryByCounty[state] && windHailDeductibleCanVaryByCounty[state][home.county]));

  return {
    buyingHomePolicyInformed: policyType?.includes(PolicyType.Home),
    allApplicantsConfirmed: primaryIsMarried,
    inColoradoInformed: state === 'CO',
    priorInsuranceEntered: stressFreeSwitch,
    noWindHailInformed,
    earthquakeAndFloodInformed: includeEarthquake || includeFlood,
    proofOfPriorCoverageInformed:
      quote?.selectedOption?.includes(PolicyType.Auto) &&
      (global.receivedCVData || (!global.receivedCVData && global.hadInforceCVPolicy)),
    lapsedLicensesVerified: lapsedLicenseMonthsStates[state] && policyType?.includes(PolicyType.Auto),
    excludedDriversInformed: drivers?.some((d) => d.excludeDriver)
  };
};

export const getExtraQuestionsConditions = (quote, selectedOptionPolicyType, isAgency) => {
  const { selectedOption, global } = quote;
  const { affinity } = global;

  const policyType = selectedOption || selectedOptionPolicyType;
  const opposingPolicySoldByAgency =
    (policyType === PolicyType.Home || policyType === PolicyType.Auto) && affinity && isAgency;

  return { opposingPolicySoldByAgency };
};
