import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { driverCarAssignmentStates, editableResidenceInsuranceType, standaloneRentersStates } from '@ourbranch/lookups';

import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import People from 'common/components/people';
import AddDriver from 'common/components/people/add-form';
import Driver from 'common/components/people/driver';
import CarAssignment from 'common/components/auto/car-assignment';
import Discounts from 'customer/components/policy/discounts';
import PipMI from 'common/components/pip';
import { useDisabledState } from 'common/disabled-context';
import Coverage from './auto-coverage';
import Cars from './cars';
import Trailers from './trailers';
import Renters from './renters';
import { ResidenceInsuranceType } from './residence-insurance-type';
import PriorCoverage from './prior-coverage';

function AutoPolicy({ policy, state }) {
  const session = useContext(AuthContext);
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();
  const { disabled } = useDisabledState();

  const allowLicensedActions = policyStore.getAllowLicensedActions(session);

  return (
    <>
      <People
        disabled={disabled || !allowLicensedActions}
        id="drivers"
        person={Driver}
        addForm={AddDriver}
        addLabel="Add Driver"
        title="Drivers"
        singular="driver"
        plural="drivers"
        fromPolicy
        hasAuto
      />
      <Cars disabled={disabled} policy={policy} fromPolicy />
      {driverCarAssignmentStates[state] && <CarAssignment state={state} disabled={disabled} />}
      <Trailers disabled={disabled} policy={policy} />
      {editableResidenceInsuranceType.includes(state) && <ResidenceInsuranceType disabled={disabled} />}
      <Coverage disabled={disabled} policy={policy} />
      <PriorCoverage />
      {!standaloneRentersStates[policy.state] && <Renters disabled={disabled} />}
      <Discounts disabled={disabled} />
      {state === 'MI' && <PipMI fromPolicy />}
    </>
  );
}

AutoPolicy.propTypes = {
  policy: PropTypes.object.isRequired
};

export default observer(AutoPolicy);
