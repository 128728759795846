import React, { useMemo, useContext } from 'react';
import { getOptions, policyType, standaloneRentersStates } from '@ourbranch/lookups';
import { flowRight } from 'lodash-es';

import withDatePicker from 'core/components/with-date-picker';
import { formattedAffinityOptions } from 'core/helpers/lookups';
import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store/store.mobx';
import useBillingDayOfMonthOptions from 'core/hooks/use-billing-day-of-month-options';
import HomeSettings from './home-settings';
import AutoSettings from './auto-settings';
import RentersSettings from './renters-settings';
import LeadSourceSettings from './lead-source-settings';

const Settings = () => {
  const { offer: store, affinityLookups } = useStore();

  const { isAgency } = useContext(AuthContext);

  const hasMonoLineHomeOption = store.offer.options.find((option) => option.type === policyType.Home);
  const hasMonoLineAutoOption = store.offer.options.find((option) => option.type === policyType.Auto);
  const hasMonoLineRentersOption = store.offer.options.find((option) => option.type === policyType.Renters);

  const homeBillingDayOptions = useBillingDayOfMonthOptions(
    store.offer,
    hasMonoLineHomeOption ? policyType.Home : policyType.HABundle
  );
  const autoBillingDayOptions = useBillingDayOfMonthOptions(
    store.offer,
    hasMonoLineAutoOption ? policyType.Auto : policyType.HABundle
  );
  const rentersBillingDayOptions = useBillingDayOfMonthOptions(
    store.offer,
    hasMonoLineRentersOption ? policyType.Renter : policyType.ARBundle
  );

  const showRenters =
    standaloneRentersStates[store.offer?.quote?.correctedAddress?.state] &&
    store.offer.options.some((o) => o.type === policyType.ARBundle || o.type === policyType.Renters);

  const {
    paymentTypeOptions,
    autoPaymentTypeOptions,
    rentersPaymentTypeOptions,
    hPaymentMethodOptions,
    aPaymentMethodOptions,
    rPaymentMethodOptions,
    leadSourceOptions,
    affinityOptions
  } = useMemo(() => {
    return {
      paymentTypeOptions: getOptions('paymentType', store.state),
      autoPaymentTypeOptions: getOptions('autoPaymentType', store.state),
      rentersPaymentTypeOptions: getOptions('rentersPaymentType', store.state),
      hPaymentMethodOptions: getOptions('homeownersPaymentMethod', store.state),
      aPaymentMethodOptions: getOptions('autoPaymentMethod'),
      rPaymentMethodOptions: getOptions('rentersPaymentMethod', store.state),
      leadSourceOptions: getOptions('leadSources'),
      affinityOptions: formattedAffinityOptions(affinityLookups)
    };
  }, [store, affinityLookups]);

  return (
    <>
      {store.shouldShowHomeTab && (
        <HomeSettings
          hPaymentMethodOptions={hPaymentMethodOptions}
          billingDaysOptions={homeBillingDayOptions}
          paymentTypeOptions={paymentTypeOptions}
          affinityOptions={affinityOptions}
          leadSourceOptions={leadSourceOptions}
          isAgency={isAgency}
        />
      )}
      {store.shouldShowAutoTab && (
        <AutoSettings
          aPaymentMethodOptions={aPaymentMethodOptions}
          billingDaysOptions={autoBillingDayOptions}
          paymentTypeOptions={autoPaymentTypeOptions}
          affinityOptions={affinityOptions}
          leadSourceOptions={leadSourceOptions}
          isAgency={isAgency}
        />
      )}
      {showRenters && (
        <RentersSettings
          paymentMethodOptions={rPaymentMethodOptions}
          billingDaysOptions={rentersBillingDayOptions}
          paymentTypeOptions={rentersPaymentTypeOptions}
          affinityOptions={affinityOptions}
          leadSourceOptions={leadSourceOptions}
          isAgency={isAgency}
        />
      )}
      <LeadSourceSettings leadSourceOptions={leadSourceOptions} affinityOptions={affinityOptions} />
    </>
  );
};

Settings.propTypes = {};

export default flowRight(withDatePicker)(Settings);
