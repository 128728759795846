import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'flex-end'
  },
  newSignUpCheckbox: {
    textAlign: 'start'
  },
  secondaryPhoneContainer: {
    border: `3px solid ${theme.colorPalette.white_10}`,
    borderRadius: 3,
    padding: '10px 0px 10px 20px',
    margin: '15px 0px'
  },
  textCheckbox: {
    marginLeft: '5px'
  }
});

export default makeStyles(styles);
