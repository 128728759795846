import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(8),
    borderBottomRightRadius: theme.sizes.roundCorners,
    borderBottomLeftRadius: theme.sizes.roundCorners
  },
  coApplicantContainer: {
    paddingBottom: theme.spacing(7)
  },
  driverContainer: {
    paddingBottom: theme.spacing(7)
  },
  coApplicant: {
    marginLeft: -3,
    marginRight: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    height: 'inherit'
  },
  coApplicantHelperText: {
    position: 'absolute',
    bottom: -12
  },
  cardFooter: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(4, 8),
    borderTop: `1px solid ${theme.colorPalette.beige_10}`,
    borderBottomRightRadius: theme.sizes.roundCorners,
    borderBottomLeftRadius: theme.sizes.roundCorners
  },
  recalculateButton: {
    marginLeft: theme.spacing(3),
    display: 'none' // Hide until backend is done
  },
  swapButtonHelperText: {
    color: theme.colors.button__background_secondary,
    width: 190
  },
  swapButtonDisabled: {
    color: theme.colorPalette.orange_50,
    opacity: '50%'
  },
  excludeDriverCheckboxDisabled: {
    marginRight: 0
  },
  driverExcluded: {
    backgroundColor: theme.colorPalette.beige_10
  },
  driverIncluded: {
    backgroundColor: theme.colors.card_background_primary_editable
  }
}));

export default useStyles;
