import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { stripeCustomerUrl } from '@ourbranch/be-constants';
import { getValue } from '@ourbranch/lookups';
import classNames from 'classnames';
import isBefore from 'date-fns/isBefore';
import flowRight from 'lodash-es/flowRight';
import { observer } from 'mobx-react';

import { ViewSchedule } from 'common/components/payment/view-schedule';
import { Loading } from 'core';
import { AuthContext } from 'core/components/auth';
import { Card } from 'core/components/card';
import { withToast } from 'core/components/toast';
import ValueField from 'core/components/value-field';
import { awsDateToDateFormatter, currencyFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';
import { HoldPaymentForm } from '../hold-payment-form';
import { ManualChargeForm } from '../manual-charge-form';
import { PastPayments } from '../past-payments';
import styles from '../payment.styles';
import PaymentMethodValue from '../value';
import { isSameDay } from 'date-fns';

const maskingDotsText = '\u25CF\u25CF\u25CF\u25CF';

export function getBillingPaymentMethod(method, brand, last4, className) {
  if (!method && !brand && !last4) {
    return 'Unknown';
  }
  if (method === 'W') {
    return getValue('homeownersPaymentMethod', method);
  }
  if (method === 'Check') {
    return method;
  }
  if (!brand && last4) {
    return (
      <>
        <PaymentMethodValue className={className}>
          ACH/ETF <span>{maskingDotsText}</span>
        </PaymentMethodValue>
        {last4}
      </>
    );
  }
  return (
    <>
      {brand && <PaymentMethodValue className={className}>{brand}</PaymentMethodValue>}
      <PaymentMethodValue className={className}>
        {maskingDotsText}
        {last4}
      </PaymentMethodValue>
    </>
  );
}

function getNextPaymentDue(amount, date, escrowPastDue) {
  if (escrowPastDue && amount) {
    return `${currencyFormatter(amount)} Unpaid`;
  }

  if (date && amount && amount > 0) {
    return `${currencyFormatter(amount)} on ${awsDateToDateFormatter(date)}`;
  }
  if (amount < 0) {
    return `${currencyFormatter(amount, { removeNegativeSign: true })}`;
  }

  return 'On Policy Renewal';
}

function getNextPaymentDueLabel({ pastDue, hasBillingHold, nextPaymentAmount, nextPaymentDate }) {
  if (hasBillingHold) {
    return 'Payment Held Until';
  }

  if (pastDue) {
    return 'Past Due';
  }

  if (nextPaymentAmount < 0 && !nextPaymentDate) {
    return 'Refund owed';
  }

  return `Next Payment ${nextPaymentAmount ? 'Due' : ''}`;
}

const BillingDetails = observer(function BillingDetails({ classes, toast }) {
  const { account } = useStore();
  const { canEdit, isService, isTeamLeader } = useContext(AuthContext);
  const [cardDetails, setCardDetails] = useState(undefined);
  const [openHoldPaymentForm, setOpenHoldPaymentForm] = useState(false);

  const {
    policies: {
      policy: { billingDetails, policy, writeOffTransactions, allTransactions }
    }
  } = account;

  const atLeastOnePaidPayment = allTransactions.some((transaction) => transaction.paymentStatus === 'paid');
  // if allTransactions.length is less than 2, default to atLeastOnePaidPayment
  const lastTwoPaymentsNotFailed =
    allTransactions.length > 1
      ? allTransactions[0].paymentStatus !== 'failed' || allTransactions[1].paymentStatus !== 'failed'
      : atLeastOnePaidPayment;

  const canAddHoldPayment = isTeamLeader || (canEdit && atLeastOnePaidPayment && lastTwoPaymentsNotFailed);

  const { setFieldValue } = useFormikContext();

  const hasBillingHold = useMemo(() => {
    return (
      policy.billingHoldUntil !== null && isBefore(new Date(policy.effectiveDate), new Date(policy.billingHoldUntil))
    );
  }, [policy.billingHoldUntil, policy.effectiveDate]);

  useEffect(() => {
    if (!cardDetails && billingDetails) {
      const { brand, last4 } = billingDetails?.activePaymentMethod || {};
      setCardDetails({
        brand,
        last4
      });
    }
  }, [cardDetails, billingDetails, setCardDetails]);

  const toggleHoldPaymentForm = useCallback(() => {
    setOpenHoldPaymentForm(!openHoldPaymentForm);
  }, [setOpenHoldPaymentForm, openHoldPaymentForm]);

  const {
    transactions,
    nextPaymentDate,
    nextPaymentAmount,
    remainingPayments,
    nextPayments,
    totalInstallments,
    totalRemaining
  } = billingDetails || {};

  const paid = useMemo(() => {
    if (transactions) {
      return transactions.filter((t) => ['paid', 'partially refunded', 'pending'].includes(t.paymentStatus));
    }
  }, [transactions]);

  const escrowPastDue =
    policy.paymentType === 'E' &&
    totalRemaining > 0 &&
    transactions.filter((t) => t.paymentStatus === 'unpaid').length > 0 &&
    !isBefore(new Date(), new Date(policy.effectiveDate)) &&
    !isSameDay(new Date(), new Date(policy.effectiveDate));

  const paidAmount = useMemo(() => {
    if (paid) {
      const writeOffTotal = writeOffTransactions.reduce((total, writeOff) => total + writeOff.amount, 0);

      return paid.reduce((prev, t) => t.paymentAmount - t.paymentRefunded + prev, 0) + writeOffTotal;
    }
    return 0;
  }, [paid, writeOffTransactions]);

  // if stripe customer, add link to around text for ability to quickly jump to stripe page
  const amountPaidPendingFormatter = useCallback(
    (value) =>
      policy.stripeCustomerId?.startsWith('cus') ? (
        <a
          className={classes.stripeCustomerLink}
          href={`${stripeCustomerUrl}${policy.stripeCustomerId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {value}
        </a>
      ) : (
        value
      ),
    [policy.stripeCustomerId, classes]
  );

  const remainingPaymentsText = useMemo(() => {
    return `${remainingPayments} out of ${totalInstallments}`;
  }, [remainingPayments, totalInstallments]);

  const pastDue = escrowPastDue || (transactions[0]?.paymentStatus === 'failed' && totalRemaining > 0);

  if (!billingDetails || !policy) {
    return <Loading type="secondary" />;
  }

  return (
    <Card type="secondary">
      <Grid
        container
        alignItems="flex-start"
        justify="space-between"
        className={classNames(classes.firstRow, { [classes.pastDueRow]: pastDue })}
      >
        <ValueField
          label="Amount Paid/Pending"
          value={currencyFormatter(paidAmount)}
          mode="dark"
          formatter={amountPaidPendingFormatter}
          classes={{ container: classes.paymentValue }}
        />
        <ValueField
          label={getNextPaymentDueLabel({ pastDue, hasBillingHold, nextPaymentAmount, nextPaymentDate })}
          value={
            <div>
              <div>{getNextPaymentDue(nextPaymentAmount, nextPaymentDate, escrowPastDue)}</div>
              {canAddHoldPayment && !openHoldPaymentForm && (
                <Button
                  onClick={() => {
                    setOpenHoldPaymentForm(true);
                  }}
                  variant="text"
                  color="secondary"
                >
                  {hasBillingHold ? 'Edit Hold' : 'Hold Payment'}
                </Button>
              )}
            </div>
          }
          mode="dark"
          classes={{ container: classes.paymentValue }}
        />
        {policy.paymentType === 'I' && (
          <div>
            <ValueField
              label="Remaining Payments"
              value={remainingPaymentsText}
              mode="dark"
              classes={{ container: classes.paymentValue }}
            />
            <ViewSchedule nextPayments={nextPayments} />
          </div>
        )}
        {cardDetails && (
          <ValueField
            label="Payment Method"
            value={
              <div>
                {policy.paymentMethod === 'C'
                  ? getBillingPaymentMethod('C', cardDetails.brand, cardDetails.last4, classes.creditCardLast4)
                  : getValue('homeownersPaymentMethod', policy.paymentMethod)}
              </div>
            }
            mode="dark"
            classes={{ container: classes.paymentValue }}
          />
        )}
      </Grid>

      {canAddHoldPayment && openHoldPaymentForm && (
        <Grid container>
          <HoldPaymentForm
            close={toggleHoldPaymentForm}
            hasBillingHold={hasBillingHold}
            setFieldValue={setFieldValue}
          />
        </Grid>
      )}
      {isService && <ManualChargeForm hasBillingHold={hasBillingHold} />}
      <PastPayments transactions={allTransactions} getBillingPaymentMethod={getBillingPaymentMethod} />
    </Card>
  );
});

BillingDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  toast: PropTypes.object.isRequired,
  billingHoldUntil: PropTypes.string
};

BillingDetails.defaultProps = {
  billingHoldUntil: null
};

export default flowRight(withStyles(styles), withToast)(BillingDetails);
