import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';

import { Loading } from 'core';
import { useStore } from 'core/store';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import ValueField from 'core/components/value-field';
import useStyles from './agent-details.styles';

const getFullName = (user) => {
  if (user?.attributes?.given_name || user?.attributes?.family_name) {
    return `${user?.attributes?.given_name || ''} ${user?.attributes?.family_name || ''}`;
  }
  return undefined;
};

const AgentDetails = observer(() => {
  const classes = useStyles();
  const { account, search, affinityLookups } = useStore();
  const { policies } = account;
  const recentPolicy = policies.getRecentPolicy();

  const initialProducerCognitoUsername = recentPolicy?.salesRep;
  const initialLeadSource = recentPolicy?.offer?.quote?.leadSource;
  const affinity = recentPolicy?.segments?.[0]?.global.affinity;
  const affinityData =
    affinity && affinityLookups.data.find((aff) => aff.affinity.toLowerCase() === affinity.toLowerCase());
  const isAgentSold = affinity && affinityData?.isAgency;
  const affinityInfo = isAgentSold && affinityData;
  const agencyInfo = affinityLookups.agencyInformation[affinityData?.data.informationId || affinity];

  const [agentCognitoUser, setAgencyCognitoUser] = useState();
  const [loading, setLoading] = useState(true);
  const [searchingStaffUsers, setSearchingStaffUsers] = useState(false);

  useEffect(() => {
    const isLoading = account.accountLoading || !account.id || searchingStaffUsers || affinityLookups.partialLoading;
    setLoading(isLoading);
  }, [account.accountLoading, account.id, searchingStaffUsers, affinityLookups, affinity, agencyInfo]);

  useEffect(() => {
    if (affinity && !agencyInfo && !affinityLookups.partialLoading) {
      affinityLookups.getAgencyInformation(affinityData?.data?.informationId || affinity);
    }
  }, [affinityLookups, isAgentSold, affinityData, affinity, agencyInfo]);

  useEffect(() => {
    if (initialProducerCognitoUsername && isAgentSold) {
      const getAgentDataFromAlgoliaCognitoIndex = async () => {
        setSearchingStaffUsers(true);
        const user = await search.searchStaffUsers({ username: initialProducerCognitoUsername });
        setAgencyCognitoUser(user);
        setSearchingStaffUsers(false);
      };

      getAgentDataFromAlgoliaCognitoIndex();
    }
  }, [initialProducerCognitoUsername, isAgentSold, search]);

  if (!recentPolicy) return null;

  return (
    <Section title="Agent Details">
      {loading ? (
        <Loading />
      ) : (
        <Card>
          <Grid container className={classes.card}>
            <Grid item xs={12}>
              <ValueField label="Initial Producer" value={initialProducerCognitoUsername || 'N/A'} />
            </Grid>
            {isAgentSold && (
              <>
                <Grid item xs={12}>
                  <ValueField label="Agent Full Name" value={getFullName(agentCognitoUser) || 'N/A'} />
                </Grid>
                <Grid item xs={12}>
                  <ValueField label="Agent Email" value={agentCognitoUser?.attributes?.email || 'N/A'} />
                </Grid>
                <Grid item xs={12}>
                  <ValueField label="Agent Phone Number" value={agentCognitoUser?.attributes?.phone_number || 'N/A'} />
                </Grid>
                <Grid item xs={12}>
                  <ValueField label="Agency Name" value={agencyInfo?.name || affinityInfo?.name || 'N/A'} />
                </Grid>
                <Grid item xs={12}>
                  <ValueField label="Agency Email" value={agencyInfo?.email || affinityInfo?.ccEmail || 'N/A'} />
                </Grid>
                <Grid item xs={12}>
                  <ValueField label="Agency Phone Number" value={agencyInfo?.phone || affinityInfo?.phone || 'N/A'} />
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              <ValueField label="Initial Lead Source" value={initialLeadSource || 'N/A'} />
            </Grid>
            <Grid item xs={6}>
              <ValueField label="Initial Affinity Code" value={affinity || 'N/A'} />
            </Grid>
          </Grid>
        </Card>
      )}
    </Section>
  );
});

export default AgentDetails;
